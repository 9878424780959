import React, { FunctionComponent } from 'react'
import { View } from 'react-native'
import { Path, Svg } from 'react-native-svg'

import { isString } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { NumberBadge } from './NumberBadge'
import { tID } from '../../utils'

const MessageIconContainer = styled(Svg)`
  z-index: 1;
`

const BadgeContainer = styled.View<{ size: number }>`
  position: absolute;
  padding: 10% 0;
  z-index: 2;

  top: ${({ size }) => -size / 2}px;
  right: ${({ size }) => -size / 2.5}px;
`

const NumberBadgeContainer = styled(NumberBadge)<{ size: number }>`
  border-width: ${({ size }) => (size < 30 ? size / 12 : size / 24)}px;
  line-height: ${({ size }) => (size < 30 ? size / 2 : size / 3)}px;
  border-radius: ${({ size }) => size / 4}px;
`

/**
 * Message Icon that can display a number badge representing unread messages
 */
export const MessageIcon: FunctionComponent<MessageIconProps> = ({
  size = 24,
  fillColor,
  unreadMessages = 0,
  isFilled,
}) => {
  size = isString(size) ? parseInt(size) : size
  const { colors } = useTheme()
  const { numberBadge } = colors.icons.messageIcon

  return (
    <View testID={tID('MessageIcon')}>
      {unreadMessages > 0 ? (
        <BadgeContainer size={size}>
          <NumberBadgeContainer number={String(unreadMessages)} borderColor={numberBadge.border} size={size} />
        </BadgeContainer>
      ) : null}
      <MessageIconContainer width={size} height={size} viewBox='0 0 24 24'>
        {isFilled ? (
          <Path
            fill={fillColor || colors.iconDefault}
            d='M20,2 L4,2 C2.9,2 2.01,2.9 2.01,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M9,11 L7,11 L7,9 L9,9 L9,11 Z M13,11 L11,11 L11,9 L13,9 L13,11 Z M17,11 L15,11 L15,9 L17,9 L17,11 Z'
          />
        ) : (
          <Path
            fill={fillColor || colors.iconDefault}
            d='M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M20,16 L6,16 L4,18 L4,4 L20,4 L20,16 Z M7,9 L9,9 L9,11 L7,11 L7,9 Z M11,9 L13,9 L13,11 L11,11 L11,9 Z M15,9 L17,9 L17,11 L15,11 L15,9 Z'
          />
        )}
      </MessageIconContainer>
    </View>
  )
}

type MessageIconProps = {
  size?: string | number
  fillColor?: string
  unreadMessages?: number
  isFilled?: boolean
}
