import React from 'react'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  BulletedList,
  Link,
  PrimaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import {
  BodyTextContainer,
  ButtonContainer,
  HeaderContainer,
  SchedulingWalkthroughComponentProps,
  WalkthroughContentContainer,
} from '../constants'

export const BulletedListContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['12px'],
}))

export const SchedulingWalkthroughDA: React.FC<SchedulingWalkthroughComponentProps> = ({ connectCalendar }) => {
  const { colors } = useTheme()

  const instructionsLink = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_HOW_TO_CONNECT })
    window.open(
      'https://provider-support.lyrahealth.com/hc/en-us/articles/35833741844115-Understanding-the-Google-Calendar-Integration-with-Lyra',
      '_blank',
    )
  }

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Let’s connect Lyra to your Google Calendar'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text={
            <>
              On the next page, you will be asked to select the Google Account you’d like us to use to view your
              availability.{' '}
              <BodyText
                color={colors.textSecondary}
                style={{ fontStyle: 'italic' }}
                text={
                  <>
                    Our system can{' '}
                    <BodyText
                      color={colors.textSecondary}
                      style={{ fontWeight: 'bold', fontStyle: 'normal' }}
                      text='only'
                    />{' '}
                    see openings on your calendar; neither Lyra nor Lyra clients can see any other appointment details
                    on your Google Calendar.
                  </>
                }
              />
            </>
          }
        />
      </BodyTextContainer>
      <HeaderContainer>
        <Subhead level='2' size={SubheadSize.MEDIUM} textAlign='left' text='Tips' color={colors.textPrimary} />
      </HeaderContainer>
      <BulletedListContainer>
        <BulletedList
          spaceBetween={0}
          textColor={colors.textSecondary}
          bulletColor={colors.textPrimary}
          data={[
            {
              value: (
                <BodyText
                  color={colors.textSecondary}
                  text={
                    <>
                      If you <BodyText style={{ fontWeight: 'bold' }} text='do not' /> see your preferred Google Account
                      listed on the next page, click{' '}
                      <BodyText style={{ fontWeight: 'bold' }} text={<>“Use another account”</>} /> and log in to the
                      desired Google Account.
                    </>
                  }
                />
              ),
            },
            {
              value: (
                <BodyText
                  color={colors.textSecondary}
                  text={
                    <>
                      If you <BodyText style={{ fontWeight: 'bold' }} text='do not' /> have a Google Account already,
                      you can sign up for a free account by clicking{' '}
                      <BodyText style={{ fontWeight: 'bold' }} text={<>“Create Account”</>} /> on the next screen.
                    </>
                  }
                />
              ),
            },
            {
              value: (
                <BodyText
                  color={colors.textSecondary}
                  text={
                    <>
                      <BodyText color={colors.textSecondary} style={{ fontStyle: 'italic' }} text='(Optional)' /> After
                      you have linked a Google Account, if you would like to explore options for syncing your
                      appointment data from other systems to Google Calendar (ex. Simple Practice, iCloud, Outlook,
                      etc.) please review{' '}
                      <Link text='these instructions' onPress={instructionsLink} size={BodyTextSize.DEFAULT} /> in our
                      Help Center.
                    </>
                  }
                />
              ),
            },
          ]}
        />
      </BulletedListContainer>
      <ButtonContainer>
        <PrimaryButton
          text='Continue'
          onPress={connectCalendar}
          testID={tID('SchedulingWalkthroughDA-completeWalkthrough')}
        />
      </ButtonContainer>
    </WalkthroughContentContainer>
  )
}
