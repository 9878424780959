import React from 'react'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'

import { diffPricingSessionTypes, sessionTypes } from '../../constants/appConstants'

export const DifferentialPricingSessionTypes: React.FC<{ sessionType: string }> = ({ sessionType }) => {
  const { differentialPricingRatesEnabled } = useFlags()
  const sessionTypeList = differentialPricingRatesEnabled ? diffPricingSessionTypes : sessionTypes
  return <p>{sessionTypeList[sessionType]}</p>
}
