import React, { FunctionComponent, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { AlertIconStroke, BodyText, Subhead } from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderBookableCalendarSlotTargetProps = {
  target: number
  current: number
  max: number
  showWarningBelowTarget?: boolean
}

enum State {
  NONE,
  WARNING,
}

const Container = styled.View<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['4px'],
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '8px',
  padding: theme.spacing['8px'],
  backgroundColor,
}))

export const ProviderBookableCalendarSlotTarget: FunctionComponent<ProviderBookableCalendarSlotTargetProps> = ({
  target,
  current,
  max,
  showWarningBelowTarget = false,
}) => {
  const theme = useTheme() as ThemeType
  const state = useMemo(() => {
    if (current > max) {
      return State.WARNING
    }

    if (showWarningBelowTarget && current < target) {
      return State.WARNING
    }

    return State.NONE
  }, [current, max, showWarningBelowTarget, target])

  const { icon, textColor, backgroundColor } = useMemo(() => {
    switch (state) {
      case State.WARNING:
        return {
          icon: <AlertIconStroke fillColor={theme.colors.iconWarning} size={24} />,
          textColor: theme.colors.textWarning,
          backgroundColor: theme.colors.backgroundWarning,
        }
      case State.NONE:
        return {
          icon: null,
          textColor: theme.colors.textPrimary,
          backgroundColor: theme.colors.backgroundPrimary,
        }
    }
  }, [state, theme.colors])
  return (
    <Container testID={tID('ProviderBookableCalendarSlotTarget')} backgroundColor={backgroundColor}>
      {icon}
      <Subhead size={SubheadSize.LARGE} text={current} color={textColor} />
      <BodyText color={textColor} text={<FormattedMessage defaultMessage=' of ' description='Text for of' />} />
      <Subhead size={SubheadSize.LARGE} text={target} color={textColor} />
      <BodyText
        color={textColor}
        text={<FormattedMessage defaultMessage='Calendar spots' description='Text for calendar spots label' />}
      />
    </Container>
  )
}
