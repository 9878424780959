import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { addDays, addMilliseconds, differenceInCalendarWeeks, isFriday, parseISO, subMilliseconds } from 'date-fns'
import { isEmpty } from 'lodash-es'
import { bindActionCreators } from 'redux'
import styledWeb from 'styled-components'
import styled from 'styled-components/native'

import {
  Appointment,
  BookingMode,
  CalendarAlert,
  CalendarAlertArgs,
  CalendarAlertType,
  DAILY_END_TIME,
  DAILY_START_TIME,
  getTimezoneOffset,
  isBookableEvent,
  LyraEventExtendedPropsNoTZ,
  ProviderCalendarEvent,
  ProviderGoogleEvent,
  SLICE_DURATION_USER_MINUTES,
  useFlags,
  useVisibleInterval,
} from '@lyrahealth-inc/shared-app-logic'
import {
  LoadingIndicator,
  Modal,
  ProviderCalendar,
  ProviderCalendarRef,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import './providerCalendar.scss'
import { CalendarIsLiveConfirmationModal } from './CalendarIsLiveModal'
import CalendarSetupCard from './CalendarSetupCard'
import {
  getCalendarAvailabilitySlots,
  getCalendarEvents,
  getCalendarProvider,
  getCalendars,
  getCalendarToken,
  getGoogleEvent,
  hideCalendarLiveModal,
  patchCalendarEvent,
  postCalendarEvents,
} from './data/calendarActions'
import {
  getCalendarAvailabilitySlots as getCalendarAvailabilitySlotsSelector,
  getCalendarConfiguration,
  getCalendarGoogleEvents,
  getCalendarProvider as getCalendarProviderSelector,
  getCalendarRequiresAuthorization,
  getCalendarShowCalendarLiveModal,
} from './data/calendarSelectors'
import {
  getBookableTarget,
  getSessionExtendedProperties,
  getWorkingHours,
  mapEventToBookableEvent,
  mapExtendedProps,
} from './utils'
import { actions as mixpanelActions, mixpanelEvents } from '../../../mixpanel/mixpanelConstants'
import { track } from '../../../mixpanel/mixpanelTracking'
import { ProviderAvailabilityResponse } from '../common/components/availabilityCard/AvailabilityCard'
import { CLIENT_HOME, SETTINGS } from '../common/constants/routingConstants'
import { getTotalCapacity } from '../common/utils/utils'
import {
  getAuthEmploymentStatus,
  getAuthProgramTaxonomies,
  getAuthUserCapacityValue,
  getAuthUserId,
} from '../data/auth/authSelectors'
import { getClientAppointmentsProviderAvailability } from '../data/lyraTherapy/clientSelectors'
import { getRequestPaymentSelectedProvider } from '../data/requestPayment/requestPaymentSelectors'
import { AvailabilityAndCapacityMismatchModal } from '../lyraTherapy/clients/AvailabilityAndCapacityMismatchModal'
import {
  getLTAppointments,
  getProviderAvailabilityV2,
} from '../lyraTherapy/clients/clientDetails/data/appointmentsAutoActions'
import { getLtClient } from '../lyraTherapy/clients/clientDetails/data/ltClientDetailsAutoActions'
import ProviderAvailabilityModal from '../lyraTherapy/clients/ProviderAvailabilityModal'
import { setToastContent } from '../lyraTherapy/data/ltToastAutoActions'
import { getLTVideoAppointments } from '../lyraTherapy/data/ltVideoSelectors'
import { updateProviderCapacity, updateSelectedProviderCapacity } from '../providers/data/providersDataActions'

const SetupCard = styled(CalendarSetupCard)(({ theme }) => ({
  paddingTop: theme.spacing['72px'],
}))

const CalendarContainer = styledWeb.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
})

const CurrentCalendar: FunctionComponent<CurrentCalendarProps> = ({
  actions: {
    getCalendarEvents,
    getCalendarToken,
    getCalendarAvailabilitySlots,
    getLTAppointments,
    getLtClient,
    patchCalendarEvent,
    getCalendars,
    setToastContent,
    postCalendarEvents,
    getGoogleEvent,
    getCalendarProvider,
    hideCalendarLiveModal,
    updateProviderCapacity,
    updateSelectedProviderCapacity,
    getProviderAvailabilityV2,
  },
}) => {
  const {
    isInProductCalendarEnabled,
    showInProductCalendarAlerts,
    shouldShowCapacityToAvailabilityRatioAlert,
    inProductCalendarRefreshIntervalMS,
  } = useFlags()
  const userId = useSelector(getAuthUserId)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const requiresAuth = useSelector(getCalendarRequiresAuthorization)
  const availabilitySlots = useSelector(getCalendarAvailabilitySlotsSelector)
  const appointments = useSelector(getLTVideoAppointments)
  const calendarConfiguration = useSelector(getCalendarConfiguration)
  const googleEvents = useSelector(getCalendarGoogleEvents)
  const showCalendarLiveModal = useSelector(getCalendarShowCalendarLiveModal)
  const navigate = useNavigate()
  const calendarProvider = useSelector(getCalendarProviderSelector)
  const calendarRef = useRef<ProviderCalendarRef>(null)
  const appointmentsMap = useRef<{ [key: number]: Appointment }>({})
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const capacities = useSelector(getAuthUserCapacityValue)
  const totalCapacity = useMemo(() => getTotalCapacity(capacities), [capacities])
  const [isCapacityModalOpen, setCapacityModalOpen] = useState(false)
  const [isMismatchModalOpen, setMismatchModalOpen] = useState(false)
  const selectedProvider = useSelector(getRequestPaymentSelectedProvider)
  const [availabilityCapacityMismatchCount, setAvailabilityCapacityMismatchCount] = useState(0)
  const [isUpdatingCapacity, setIsUpdatingCapacity] = useState(false)
  const providerAvailability: ProviderAvailabilityResponse[] =
    useSelector(getClientAppointmentsProviderAvailability) ?? []
  const providerAvailabilities = providerAvailability && providerAvailability[0]?.availability
  const authUserProgramTaxonomies = useSelector(getAuthProgramTaxonomies)

  useEffect(() => {
    appointmentsMap.current = appointments.reduce((acc, curr) => {
      acc[curr.appointmentId] = curr
      return acc
    }, {} as { [key: number]: Appointment })
  }, [appointments])

  useFetcher(
    [
      [getCalendarToken, { providerId: userId }, !!userId],
      [getLTAppointments, { id: userId }, !!userId],
      [getCalendarAvailabilitySlots, { providerId: userId }, !!userId],
      [getCalendars, { providerId: userId }, !!userId],
      [getCalendarProvider, { providerId: userId }, !!userId],
    ],
    [userId],
  )

  const pollingIntervalMs = useMemo(
    () => Math.max(inProductCalendarRefreshIntervalMS, 1000 * 60 * 3),
    [inProductCalendarRefreshIntervalMS],
  )
  const refetchData = useCallback(() => {
    if (window.Cypress) {
      return
    }
    getLTAppointments({ id: userId })
    calendarRef.current?.refresh()
  }, [getLTAppointments, userId])

  useVisibleInterval(refetchData, { intervalMs: pollingIntervalMs })

  useFetcher(
    [
      [
        getProviderAvailabilityV2,
        {
          providerId: userId,
          params: {
            slice_duration_minutes: SLICE_DURATION_USER_MINUTES,
            start_datetime: new Date().toISOString(),
            end_datetime: addDays(new Date(), 14).toISOString(),
            daily_start_time: DAILY_START_TIME,
            daily_end_time: DAILY_END_TIME,
            clientele: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.clientele) ?? '',
            treatment: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.treatment) ?? '',
            partner: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.partner) ?? '',
            offering: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.offering) ?? '',
            appointment_class: 'initial',
            lead_time_hours: 0,
          },
        },
      ],
    ],
    [userId, authUserProgramTaxonomies],
  )

  const workingHours = getWorkingHours(employmentStatus)
  const targetHours = getBookableTarget(employmentStatus)

  const updateCapacity = useCallback(
    (value: number) => {
      if (!userId) {
        return
      }
      const updateCapacity = isEmpty(selectedProvider) ? updateProviderCapacity : updateSelectedProviderCapacity
      const newCapacities = { ...capacities }
      if ('BlendedCareTherapy' in newCapacities && newCapacities.BlendedCareTherapy != null) {
        newCapacities.BlendedCareTherapy += value
      }
      setIsUpdatingCapacity(true)
      updateCapacity({
        id: userId,
        data: { capacity: newCapacities },
      })
        .then(() => {
          setToastContent({
            text: 'Capacity updated',
            id: 'Capacity-Saved-Toast-Success',
            toastType: 'success',
          })
        })
        .finally(() => {
          setIsUpdatingCapacity(false)
        })
    },
    [capacities, selectedProvider, setToastContent, updateProviderCapacity, updateSelectedProviderCapacity, userId],
  )
  const getAlerts = useCallback<(args: CalendarAlertArgs) => CalendarAlert[]>(
    ({ timeOffHours, clientHours, startDate, scheduledSessions }) => {
      if (!showInProductCalendarAlerts) {
        return []
      }
      const expectedHours = Math.ceil(((workingHours - timeOffHours) / workingHours) * targetHours)
      const alerts: CalendarAlert[] = []
      if (clientHours < expectedHours) {
        const slotsNeeded = expectedHours - clientHours
        alerts.push({
          type: CalendarAlertType.BOOKABLE,
          badge: (
            <FormattedMessage
              defaultMessage='Add {slotsNeeded}'
              description='Slots needed to meet target hours'
              values={{ slotsNeeded }}
            />
          ),
          title: (
            <FormattedMessage
              defaultMessage='Add {slotsNeeded} calendar spots'
              description='Slots needed to meet target hours'
              values={{ slotsNeeded }}
            />
          ),
          body: (
            <FormattedMessage
              defaultMessage='This week you are expected to have {expectedHours, plural,
                one {{expectedHours, number} calendar spot.}
                other {{expectedHours, number} calendar spots.}}'
              description='Desciption of alert for not meeting target hours'
              values={{ expectedHours }}
            />
          ),
          trackingEvent: mixpanelEvents.ALERT_SHOWN_UNDER_BOOKABLE,
        })
      }

      if (clientHours > expectedHours && timeOffHours > 0) {
        const slotsNeeded = clientHours - expectedHours
        alerts.push({
          type: CalendarAlertType.BOOKABLE,
          badge: (
            <FormattedMessage
              defaultMessage='Time off: delete {slotsNeeded}'
              description='Number of slots that need to be removed'
              values={{ slotsNeeded }}
            />
          ),
          title: (
            <FormattedMessage
              defaultMessage='Delete up to {slotsNeeded} calendar spots'
              description='Number of slots that need to be removed'
              values={{ slotsNeeded }}
            />
          ),
          body: (
            <FormattedMessage
              defaultMessage='Due to your time off hours entered this week, your calendar spots expectation is reduced to {expectedHours}.'
              description='Desciption of alert for not meeting target hours'
              values={{ expectedHours: Math.max(expectedHours, 0) }}
            />
          ),
          trackingEvent: mixpanelEvents.ALERT_SHOWN_OVER_BOOKABLE_TIME_OFF,
        })
      }

      const tzOffset = getTimezoneOffset(timeZone, startDate)
      const startDateLocal = subMilliseconds(parseISO(startDate), tzOffset)
      if (
        isFriday(new Date()) &&
        differenceInCalendarWeeks(startDateLocal, new Date()) === 1 &&
        scheduledSessions + totalCapacity < expectedHours
      ) {
        const unfilledSessions = expectedHours - (scheduledSessions + totalCapacity)
        alerts.push({
          type: CalendarAlertType.SCHEDULED,
          badge: (
            <FormattedMessage
              defaultMessage='Add {unfilledSessions, plural,
                one {{unfilledSessions, number} client}
                other {{unfilledSessions, number} clients}}'
              description='Badge label for unfilled sesssions alert'
              values={{ unfilledSessions }}
            />
          ),
          title: (
            <FormattedMessage
              defaultMessage='Add {unfilledSessions, plural,
              one {{unfilledSessions, number} client}
              other {{unfilledSessions, number} clients}}'
              description='Title for unfilled sessions alert'
              values={{ unfilledSessions }}
            />
          ),
          body: (
            <FormattedMessage
              defaultMessage='To meet your bookable expectation, either schedule more followup sessions or set your new client capacity to {newCapacity}'
              description='Body for unfilled sessions alert'
              values={{ newCapacity: totalCapacity + unfilledSessions }}
            />
          ),
          actions: {
            primary: {
              label: (
                <FormattedMessage
                  defaultMessage='Add for me'
                  description='Button to auto add capacity to allow open slots to be filled'
                />
              ),
              testID: 'ProviderCalendarAlert-add-for-me-button',
              onClick: () => {
                track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CAPACITY_ALERT_ADD_FOR_ME })
                updateCapacity(unfilledSessions)
              },
              isLoading: isUpdatingCapacity,
            },
            secondary: {
              testID: 'ProviderCalendarAlert-open-capacity-button',
              label: (
                <FormattedMessage defaultMessage='Open capacity' description='Button label to open capacity modal' />
              ),
              onClick: () => {
                track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CAPACITY_ALERT_OPEN_CAPACITY })
                setCapacityModalOpen(true)
              },
            },
          },
          trackingEvent: mixpanelEvents.ALERT_SHOWN_CAPACITY,
          closeTrackingAction: mixpanelActions.CAPACITY_ALERT_X,
        })
      }

      return alerts
    },
    [
      isUpdatingCapacity,
      showInProductCalendarAlerts,
      targetHours,
      timeZone,
      totalCapacity,
      updateCapacity,
      workingHours,
    ],
  )

  const getEventsForRange = useCallback(
    async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
      if (!userId) {
        return []
      }
      const tzOffset = getTimezoneOffset(timeZone, startDate)
      const { data: events } = await getCalendarEvents({
        startDate: subMilliseconds(parseISO(startDate), tzOffset).toISOString(),
        endDate: subMilliseconds(parseISO(endDate), tzOffset).toISOString(),
        providerId: userId,
      })
      return events
        .filter((event) => {
          if (event.is_blocked) {
            return false
          }
          if (event.event_type === 'bookable_recurring') {
            return false
          }

          return true
        })
        .map((event) => {
          const extendedProps = mapExtendedProps(event, appointmentsMap.current)
          return {
            id: event.id,
            title: event.title,
            start: addMilliseconds(parseISO(event.start_datetimetz), tzOffset).toISOString(),
            end: addMilliseconds(parseISO(event.end_datetimetz), tzOffset).toISOString(),
            startEditable: isBookableEvent(extendedProps),
            extendedProps,
          }
        })
    },
    [getCalendarEvents, timeZone, userId],
  )

  useEffect(() => {
    if (appointments.length > 0) {
      const extendedPropsMap = appointments.reduce((acc, curr) => {
        acc[curr.appointmentId] = getSessionExtendedProperties(curr)
        return acc
      }, {} as { [key: number]: LyraEventExtendedPropsNoTZ })
      calendarRef.current?.updateAppointments(extendedPropsMap)
    }
  }, [appointments])

  const onEventMoved = useCallback(
    (event: { externalId: string; startTime: string; endTime: string }, revert: () => void) => {
      if (!calendarConfiguration?.id) {
        revert()
        return
      }
      track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_MANAGEMENT_EDIT_AVAILABLE })
      patchCalendarEvent({
        external_id: event.externalId,
        body: {
          provider_calendar_configuration_id: calendarConfiguration.id,
          event: {
            start: event.startTime,
            end: event.endTime,
          },
        },
      })
        .then(() => {
          setToastContent({
            text: 'Availability updated this week',
            toastType: 'success',
            id: 'CurrentCalendar-move-success',
          })
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to update availability',
            toastType: 'error',
            id: 'CurrentCalendar-move-error',
          })
          revert()
        })
    },
    [calendarConfiguration, patchCalendarEvent, setToastContent],
  )

  const onEventsAdded = useCallback(
    (
      events: { startTime: string; endTime: string }[],
      revert: () => void,
      attachEventID: (events: { id: string }[]) => void,
    ) => {
      if (!calendarConfiguration?.id) {
        revert()
        return
      }
      track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_MANAGEMENT_ADD_AVAILABLE })
      postCalendarEvents({
        provider_calendar_configuration_id: calendarConfiguration?.id,
        events: events.map((event) => mapEventToBookableEvent(event, timeZone, false)),
      })
        .then((response) => {
          setToastContent({
            text: 'Availability added this week',
            toastType: 'success',
            id: 'CurrentCalendar-add-success',
          })
          attachEventID(response.data)
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to add availability',
            toastType: 'error',
            id: 'CurrentCalendar-add-error',
          })
          revert()
        })
    },
    [calendarConfiguration?.id, postCalendarEvents, setToastContent, timeZone],
  )

  const onEventDeleted = useCallback(
    (event: { externalId: string }, revert: () => void) => {
      if (!calendarConfiguration?.id) {
        revert()
        return
      }
      track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_MANAGEMENT_DELETE_AVAILABLE })
      patchCalendarEvent({
        external_id: event.externalId,
        body: {
          provider_calendar_configuration_id: calendarConfiguration.id,
          event: {
            status: 'cancelled',
          },
        },
      })
        .then(() => {
          setToastContent({
            text: 'Availability deleted this week',
            toastType: 'success',
            id: 'CurrentCalendar-delete-success',
          })
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to delete availability',
            toastType: 'error',
            id: 'CurrentCalendar-delete-error',
          })
          revert()
        })
    },
    [calendarConfiguration, patchCalendarEvent, setToastContent],
  )

  const getOrFetchGoogleEvent = useCallback(
    async (externalId: string, calendarConfigurationId: string) => {
      if (externalId in googleEvents) {
        return googleEvents[externalId]
      }

      const event = await getGoogleEvent({
        external_id: externalId,
        provider_calendar_configuration_id: calendarConfigurationId,
      })
      return event.data
    },
    [getGoogleEvent, googleEvents],
  )

  const onOpenInGoogleCalendar = useCallback(
    (externalId: string, calendarConfigurationId: string) => {
      track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_MANAGEMENT_OPEN_IN_GOOGLE_CALENDAR })
      getOrFetchGoogleEvent(externalId, calendarConfigurationId)
        .then((event) => {
          window.open(event.htmlLink, '_blank')
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to open google calendar',
            toastType: 'error',
            id: 'CurrentCalendar-open-google-error',
          })
        })
    },
    [getOrFetchGoogleEvent, setToastContent],
  )

  const onOpenVideoConferencing = useCallback(
    (externalId: string, calendarConfigurationId: string) => {
      track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_MANAGEMENT_JOIN_MEETING })
      getOrFetchGoogleEvent(externalId, calendarConfigurationId)
        .then((event) => {
          const zoomUrl = event.conferenceData?.entryPoints.find(
            (entryPoint) => entryPoint.entryPointType === 'video',
          )?.uri
          if (zoomUrl) {
            window.open(zoomUrl, '_blank')
          } else {
            setToastContent({
              text: 'Failed to open zoom',
              toastType: 'error',
              id: 'CurrentCalendar-open-zoom-error',
            })
          }
        })
        .catch(() => {
          setToastContent({
            text: 'Failed to open zoom',
            toastType: 'error',
            id: 'CurrentCalendar-open-zoom-error',
          })
        })
    },
    [getOrFetchGoogleEvent, setToastContent],
  )

  const handleMismatchModalOpen = useCallback(() => {
    if (!isMismatchModalOpen) {
      setMismatchModalOpen(true)
      track({ event: mixpanelEvents.CAPACITY_AVAILABILITY_ALERT_MODAL_SHOWN })
    }
  }, [isMismatchModalOpen])

  if (!isInProductCalendarEnabled) {
    return null
  }

  if (requiresAuth == null || availabilitySlots == null) {
    return <LoadingIndicator />
  }

  if (requiresAuth || calendarProvider?.booking_mode !== BookingMode.BOOKABLE) {
    return <SetupCard />
  }

  return (
    <>
      <CalendarContainer className='lc-calendar'>
        <ProviderCalendar
          ref={calendarRef}
          timeZone={timeZone}
          getEvents={getEventsForRange}
          loading={requiresAuth === undefined}
          onSettingsPressed={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.NAVIGATION_CALENDAR_GEAR })
            navigate(SETTINGS.route)
          }}
          onClientProfilePressed={async (clientId) => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CALENDAR_MANAGEMENT_CLIENT_DETAILS })
            await getLtClient({ clientId, providerId: userId })
            navigate({ pathname: CLIENT_HOME.route })
          }}
          onEventMoved={onEventMoved}
          onEventsAdded={onEventsAdded}
          onEventDeleted={onEventDeleted}
          onOpenInGoogleCalendar={onOpenInGoogleCalendar}
          onOpenVideoConferencing={onOpenVideoConferencing}
          onOpenZendeskPressed={() => {
            track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.NAVIGATION_CALENDAR_ZENDESK })
            window.open(
              'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/35842493481363--PRODUCT-UPDATE-Calendar-Schedule-Feature-Day-to-Day-Calendar-Management',
              '_blank',
            )
          }}
          getAlerts={getAlerts}
        />
      </CalendarContainer>
      <CalendarIsLiveConfirmationModal visible={showCalendarLiveModal} onClosePress={hideCalendarLiveModal} />
      <Modal
        onCloseEnd={() => setCapacityModalOpen(false)}
        onRequestClose={() => setCapacityModalOpen(false)}
        disableBottomSheet={true}
        visible={isCapacityModalOpen}
        modalContents={
          <ProviderAvailabilityModal
            currentCapacity={capacities || {}}
            providerId={userId as string}
            selectedProvider={selectedProvider || {}}
            triggerMismatchModal={handleMismatchModalOpen}
            setMismatchModalCount={setAvailabilityCapacityMismatchCount}
            providerAvailabilities={providerAvailabilities}
            closeModal={() => setCapacityModalOpen(false)}
          />
        }
        width='518px'
        closeOnScrim
        scrollable
        scrollableModalHeight='100%'
      />

      {shouldShowCapacityToAvailabilityRatioAlert && (
        <AvailabilityAndCapacityMismatchModal
          onClose={() => setMismatchModalOpen(false)}
          isModalOpen={isMismatchModalOpen}
          shortfall={availabilityCapacityMismatchCount}
          providerAvailabilitiesCount={providerAvailabilities?.length ?? 0}
        />
      )}
    </>
  )
}

export type CurrentCalendarProps = {
  actions: {
    getCalendarToken: (params: Parameters<typeof getCalendarToken>[0]) => Promise<any>
    getCalendarEvents: (params: Parameters<typeof getCalendarEvents>[0]) => Promise<{ data: ProviderCalendarEvent[] }>
    getCalendarAvailabilitySlots: (params: Parameters<typeof getCalendarAvailabilitySlots>[0]) => Promise<any>
    getLTAppointments: (params: Parameters<typeof getLTAppointments>[0]) => Promise<{ data: ProviderCalendarEvent[] }>
    getLtClient: (params: Parameters<typeof getLtClient>[0]) => Promise<{ data: ProviderCalendarEvent[] }>
    patchCalendarEvent: (params: Parameters<typeof patchCalendarEvent>[0]) => Promise<any>
    getCalendars: (params: Parameters<typeof getCalendars>[0]) => Promise<any>
    setToastContent: (params: Parameters<typeof setToastContent>[0]) => Promise<any>
    postCalendarEvents: (params: Parameters<typeof postCalendarEvents>[0]) => Promise<{ data: { id: string }[] }>
    getGoogleEvent: (params: Parameters<typeof getGoogleEvent>[0]) => Promise<{ data: ProviderGoogleEvent }>
    getCalendarProvider: (params: Parameters<typeof getCalendarProvider>[0]) => Promise<any>
    hideCalendarLiveModal: () => Promise<any>
    updateProviderCapacity: (params: Parameters<typeof updateProviderCapacity>[0]) => Promise<any>
    updateSelectedProviderCapacity: (params: Parameters<typeof updateSelectedProviderCapacity>[0]) => Promise<any>
    getProviderAvailabilityV2: (params: Parameters<typeof getProviderAvailabilityV2>[0]) => Promise<any>
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getCalendarEvents: getCalendarEvents as any,
      getCalendarToken: getCalendarToken as any,
      getCalendarAvailabilitySlots: getCalendarAvailabilitySlots as any,
      getLTAppointments: getLTAppointments as any,
      getLtClient: getLtClient as any,
      patchCalendarEvent: patchCalendarEvent as any,
      getCalendars: getCalendars as any,
      setToastContent: setToastContent as any,
      postCalendarEvents: postCalendarEvents as any,
      getGoogleEvent: getGoogleEvent as any,
      getCalendarProvider: getCalendarProvider as any,
      hideCalendarLiveModal: hideCalendarLiveModal as any,
      updateProviderCapacity: updateProviderCapacity as any,
      updateSelectedProviderCapacity: updateSelectedProviderCapacity as any,
      getProviderAvailabilityV2: getProviderAvailabilityV2 as any,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)

export default connector(CurrentCalendar)
