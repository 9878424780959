import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { AssignmentResponse } from '@lyrahealth-inc/shared-app-logic'

import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { InputField } from '../../atoms/formElements/InputField'
import { Headline, Size as HeadlineSize } from '../../atoms/headline/Headline'
import { LoadingIndicator } from '../../atoms/icons/LoadingIndicator'
import { Link } from '../../atoms/link/Link'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { RowView } from '../../templates'
import { ThemeType, tID } from '../../utils'

export type ExerciseProviderCommentProps = {
  clientName: string
  fetchComment: ({ messageId }: { messageId: string }) => Promise<CommentType>
  response?: AssignmentResponse
  submitHandler?: (comment: string, response: AssignmentResponse) => Promise<AssignmentResponse>
}

export type CommentType = {
  attributes: string
  body: string
  conversation_sid?: string
  date_created: string
  message_id: string
}

const CommentContainer = styled.View<{ theme: ThemeType }>(({ theme: { colors, spacing } }) => ({
  backgroundColor: colors.backgroundSection,
  borderRadius: '16px',
  border: `1px solid ${colors.borderDefault}`,
  padding: `${spacing['24px']} ${spacing['16px']}`,
}))

const ButtonContainer = styled(RowView)({
  justifyContent: 'flex-end',
})

const CommentDate = styled(Headline)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  textTransform: 'uppercase',
  marginBottom: spacing['8px'],
}))

export const ExerciseProviderComment: FunctionComponent<ExerciseProviderCommentProps> = ({
  clientName,
  fetchComment,
  response,
  submitHandler,
}) => {
  const [comment, setComment] = useState<string>('')
  const [fetchedComment, setFetchedComment] = useState<CommentType | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { formatMessage } = useIntl()

  const getComment = useCallback(
    (messageId: string | null | undefined) => {
      if (messageId) {
        setIsLoading(true)
        fetchComment({ messageId })
          .then((comment: CommentType) => {
            setFetchedComment(comment)
          })
          .catch(() =>
            setFetchedComment({
              attributes: '',
              date_created: '',
              body: 'There was an error fetching the comment',
              message_id: '',
            }),
          )
          .finally(() => setIsLoading(false))
      }
    },
    [fetchComment],
  )

  useEffect(() => {
    if (response && response?.provider_feedback) {
      getComment(response?.provider_feedback)
    } else {
      setFetchedComment(null)
    }
  }, [getComment, response])

  const submitComment = () => {
    setIsSubmitting(true)
    submitHandler &&
      response &&
      submitHandler(comment, response).then((updatedResponse) => {
        setIsSubmitting(false)
        setComment('')
        getComment(updatedResponse.provider_feedback)
      })
  }

  // Format a URL if it is added to the comment.
  const urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;$]*[-A-Z0-9+&@#/%=~_|])/gi
  const replaceWebLinks = function (text?: string) {
    if (!text) return

    const tokens = text.split(urlRegex)
    // Replace every other token
    return tokens.map((token: string, index: number) =>
      index % 2 ? <Link key={index} text={token} onPress={() => window.open(token, '_blank', 'noreferrer')} /> : token,
    )
  }

  return isLoading || fetchedComment ? (
    <View testID={tID('ExerciseProviderComment-postedComment')}>
      {isLoading ? (
        <LoadingIndicator size={35} topPadding={10} />
      ) : (
        <CommentContainer>
          {fetchedComment?.date_created && (
            <CommentDate
              size={HeadlineSize.EYEBROW}
              text={
                <FormattedDate
                  value={fetchedComment?.date_created}
                  year='numeric'
                  month='short'
                  day='numeric'
                  hour='numeric'
                  minute='numeric'
                />
              }
            />
          )}
          <BodyText size={BodyTextSize.DEFAULT} text={replaceWebLinks(fetchedComment?.body)} />
        </CommentContainer>
      )}
    </View>
  ) : (
    <View testID={tID('ExerciseProviderComment-addComment')}>
      <InputField
        active={!isSubmitting}
        multiline
        numberOfLines={2}
        onChange={(e) => setComment(e.nativeEvent.text)}
        placeholder={formatMessage(
          {
            defaultMessage: 'Type your comment to {clientName} here...',
            description: 'Placeholder text for a field where a comment can be made.',
          },
          { clientName },
        )}
        scrollEnabled
        testID={tID('ExerciseProviderComment-addComment-input')}
        value={comment}
      />
      <ButtonContainer>
        <PrimaryButton
          disabled={comment === ''}
          loading={isSubmitting}
          onPress={submitComment}
          size={ButtonSize.SMALL}
          testID={tID('ExerciseProviderComment-addComment-btn')}
          text={formatMessage({
            defaultMessage: 'Add Comment',
            description: 'Button text to submit a comment',
          })}
        />
      </ButtonContainer>
    </View>
  )
}
