import React from 'react'

import { useTheme } from 'styled-components/native'

import { BodyText, PrimaryButton, Subhead, SubheadSize, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  BodyTextContainer,
  ButtonContainer,
  HeaderContainer,
  SchedulingWalkthroughComponentProps,
  WalkthroughContentContainer,
} from '../constants'

export const SchedulingWalkthroughLC: React.FC<SchedulingWalkthroughComponentProps> = ({ connectCalendar }) => {
  const { colors } = useTheme()

  return (
    <WalkthroughContentContainer>
      <HeaderContainer>
        <Subhead
          level='2'
          size={SubheadSize.LARGE}
          textAlign='center'
          text='Let’s get started'
          color={colors.textPrimary}
        />
      </HeaderContainer>
      <BodyTextContainer>
        <BodyText
          color={colors.textSecondary}
          textAlign='center'
          text='We’ll walk you through the calendar integration process step-by-step. This requires managing your calendar settings and will take around 15 minutes.'
        />
      </BodyTextContainer>
      <ButtonContainer>
        <PrimaryButton
          text='Continue'
          onPress={connectCalendar}
          testID={tID('SchedulingWalkthroughLC-completeWalkthrough')}
        />
      </ButtonContainer>
    </WalkthroughContentContainer>
  )
}
