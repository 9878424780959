import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components/native'

import { LoadingIndicator, ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import { SchedulingWalkthroughDA } from './SchedulingWalkthroughDA'
import { SchedulingWalkthroughLC } from './SchedulingWalkthroughLC'
import { SchedulingWalkthroughNavBar } from './SchedulingWalkthroughNavBar'
import { SchedulingWalkthroughOUS } from './SchedulingWalkthroughOUS'
import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING } from '../../common/constants/routingConstants'
import { getAuthConfig, getAuthUserId } from '../../data/auth/authSelectors'
import { RootState } from '../../data/store'
import { LoadingContainer } from '../constants'
import { getSchedulingData } from '../data/schedulingActions'
import { getSchedulingAuthUrl, getSchedulingLoaded } from '../data/schedulingSelectors'

const BackgroundContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
}))

export type SchedulingWalkthroughProps = {
  schedulingAuthUrl: string
  schedulingDataLoaded: boolean
  userId: string
  actions: {
    getSchedulingData: (providerId: string) => void
  }
}

const SchedulingWalkthrough: React.FC<SchedulingWalkthroughProps> = ({
  schedulingAuthUrl,
  schedulingDataLoaded,
  userId,
  actions: { getSchedulingData },
}) => {
  const navigate = useNavigate()
  const config = useSelector(getAuthConfig)

  useEffect(() => {
    if (!schedulingDataLoaded) {
      getSchedulingData(userId)
    }
  }, [getSchedulingData, schedulingDataLoaded, userId])

  const connectGoogleCalendar = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_WALKTHROUGH_GOOGLE_CONNECTION })
    // This is to allow us to mock the redirect to google, this will shortcut to the callback url route with mock code.
    if (schedulingAuthUrl?.startsWith(SCHEDULING.route)) {
      navigate(schedulingAuthUrl)
    } else {
      window.open(schedulingAuthUrl, '_self')
    }
  }
  return (
    <BackgroundContainer>
      <SchedulingWalkthroughNavBar />
      {schedulingDataLoaded ? (
        config?.internationalSmartSchedulingEnabled ? (
          <SchedulingWalkthroughOUS connectCalendar={connectGoogleCalendar} />
        ) : config?.smartSchedulingEnabled ? (
          <SchedulingWalkthroughDA connectCalendar={connectGoogleCalendar} />
        ) : (
          <SchedulingWalkthroughLC connectCalendar={connectGoogleCalendar} />
        )
      ) : (
        <LoadingContainer>
          <LoadingIndicator size={45} />
        </LoadingContainer>
      )}
    </BackgroundContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    schedulingDataLoaded: getSchedulingLoaded(state),
    schedulingAuthUrl: getSchedulingAuthUrl(state),
    userId: getAuthUserId(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        getSchedulingData,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingWalkthrough)
