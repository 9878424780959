import { useCallback, useEffect, useRef } from 'react'

import { differenceInMilliseconds } from 'date-fns'

import { useWindowVisible } from './useWindowVisible'

type Options = {
  intervalMs: number
}

/**
 * Hook to run function every N milliseconds when window is active.
 * If window is not active, it will run the function after N milliseconds has passed since the last run time and continue the interval.
 */
export const useVisibleInterval = (fn: () => any, { intervalMs }: Options) => {
  const isActive = useWindowVisible()
  const lastRunTime = useRef<Date>(new Date())

  const runFunction = useCallback(() => {
    fn()
    lastRunTime.current = new Date()
  }, [fn])

  useEffect(() => {
    let timeout: number | undefined
    let interval: number | undefined
    if (isActive) {
      const timeDiffMs = differenceInMilliseconds(new Date(), lastRunTime.current)
      timeout = window.setTimeout(() => {
        runFunction()
        interval = window.setInterval(runFunction, intervalMs)
      }, intervalMs - timeDiffMs)
    }

    return () => {
      timeout && clearTimeout(timeout)
      interval && clearInterval(interval)
    }
  }, [intervalMs, isActive, runFunction])
}
