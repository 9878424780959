import React from 'react'
import { ImageSourcePropType } from 'react-native'
import { useNavigate } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  ContentContainer,
  Headline,
  HeadlineSize,
  PrimaryButton,
  Subhead,
  SubheadSize,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'
import smartSchedulingBackground from '@lyrahealth-inc/ui-core-crossplatform/src/assets/smartSchedulingBackground@2x.png'

import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING_ACTIVATE } from '../../common/constants/routingConstants'
import {
  DescriptionContainer,
  LandingHeaderContainer,
  LandingSubheadContainer,
  SchedulingCTAWrapper,
} from '../constants'

const TitleImageBackgroundContainer = styled.View({
  height: '100vh',
})

const ImageBackgroundLC = styled.ImageBackground({
  height: '100%',
})

export const SchedulingLandingBodyLC: React.FC = () => {
  const { colors } = useTheme()
  const navigate = useNavigate()

  const onStartConnecting = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.CONNECT_CALENDAR_START })
    navigate(SCHEDULING_ACTIVATE.route)
  }

  return (
    <TitleImageBackgroundContainer>
      <ImageBackgroundLC source={smartSchedulingBackground as ImageSourcePropType} accessibilityIgnoresInvertColors>
        <ContentContainer>
          <LandingHeaderContainer>
            <Headline level='2' size={HeadlineSize.SMALL} text='Calendar' color={colors.textPrimary} />
          </LandingHeaderContainer>
        </ContentContainer>
        <ContentContainer>
          <SchedulingCTAWrapper>
            <LandingSubheadContainer>
              <Subhead
                level='3'
                color={colors.textPrimary}
                size={SubheadSize.MEDIUM}
                text='Activate Smart Scheduling'
              />
            </LandingSubheadContainer>
            <DescriptionContainer>
              <BodyText
                color={colors.textSecondary}
                size={BodyTextSize.SMALL}
                text='Connect your calendar to Lyra so your future clients can book their sessions with you easily and quickly.'
              />
            </DescriptionContainer>
            <PrimaryButton
              text='Get started'
              onPress={onStartConnecting}
              testID={tID('SchedulingLandingBodyLC-startWalkthrough')}
            />
          </SchedulingCTAWrapper>
        </ContentContainer>
      </ImageBackgroundLC>
    </TitleImageBackgroundContainer>
  )
}
