import React, { FunctionComponent, useState } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { DropdownMenu, MENU_SELECTED_ITEM_STYLE } from '../../atoms/formElements/typeAhead/DropdownMenu'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { Subhead } from '../../atoms/subhead/Subhead'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

interface TabProps {
  activePathname: string
  activePathnameExceptions?: string[]
  currentPathname: string
  disabled?: boolean
  dropdownWidth?: number
  onPress?: () => void
  testID?: string
  text: string
}

export interface TabDropdownProps extends TabProps {
  subTabs?: TabProps[]
}

const Container = styled(PressableOpacity)({
  width: 'fit-content',
})

const TabDropdownContainer = styled.View<{ theme: ThemeType; active: boolean }>(
  ({ theme: { colors, spacing }, active }) => ({
    backgroundColor: active ? colors.backgroundHighlightPrimary : colors.backgroundTransparent,
    padding: spacing['8px'],
    borderRadius: spacing['4px'],
    flexDirection: 'row',
  }),
)

const TabDropdownText = styled(Subhead)<{ theme: ThemeType; active: boolean }>(({ theme: { colors }, active }) => ({
  color: active ? colors.textActive : colors.textSecondary,
}))

const ChevronIconContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  margin: 'auto',
  marginLeft: spacing['4px'],
}))

const DropdownMenuContainer = styled.View<{ theme: ThemeType; width?: string | number }>(
  ({ theme: { colors, spacing }, width }) => ({
    position: 'absolute',
    backgroundColor: colors.backgroundPrimary,
    padding: `${spacing['12px']} 0`,
    borderRadius: spacing['8px'],
    boxShadow: `0 2px 16px ${colors.shadowLow}`,
    ...(width && { width: width }),
    top: '100%',
    left: 0,
  }),
)

export const TabDropdown: FunctionComponent<TabDropdownProps> = ({
  activePathname,
  activePathnameExceptions,
  currentPathname,
  disabled,
  dropdownWidth,
  onPress = () => {},
  testID,
  text,
  subTabs,
}) => {
  const { colors } = useTheme()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const active =
    currentPathname.includes(activePathname) &&
    !(
      activePathnameExceptions &&
      activePathnameExceptions.find((activePathnameException) => currentPathname.includes(activePathnameException))
    )

  const selectedSubTab = subTabs
    ? subTabs.find((subTab) => {
        return (
          currentPathname.includes(subTab.activePathname) &&
          !(
            subTab.activePathnameExceptions &&
            subTab.activePathnameExceptions.find((activePathnameException) =>
              currentPathname.includes(activePathnameException),
            )
          )
        )
      })?.text ?? ''
    : ''

  return (
    <Container
      onPress={() => {
        setIsDropdownOpen((prev) => !prev)
        if (!subTabs || subTabs.length === 0) {
          onPress()
        }
      }}
      testID={tID(testID)}
      disabled={disabled}
    >
      <TabDropdownContainer active={active}>
        <TabDropdownText size={SubheadSize.XSMALL} active={active} text={text} />
        {subTabs && subTabs.length > 0 && (
          <ChevronIconContainer>
            <ChevronIcon
              direction={isDropdownOpen && active ? ChevronIconDirection.UP : ChevronIconDirection.DOWN}
              fillColor={active ? colors.textActive : colors.textSecondary}
            />
          </ChevronIconContainer>
        )}
      </TabDropdownContainer>
      {subTabs && subTabs.length > 0 && isDropdownOpen && (
        <DropdownMenuContainer testID={tID(`TabDropdown-dropdownContainer`)} width={dropdownWidth}>
          <DropdownMenu
            options={subTabs.map((option) => option.text)}
            onOptionPress={(selected) => {
              const subTab = subTabs.find((option) => option.text === selected)
              subTab?.onPress && subTab.onPress()
              setIsDropdownOpen((prev) => !prev)
            }}
            value={[selectedSubTab]}
            selectedItemStyle={MENU_SELECTED_ITEM_STYLE.LEFT_BORDER}
            customOptions={subTabs}
          />
        </DropdownMenuContainer>
      )}
    </Container>
  )
}
