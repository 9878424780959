import React, { FunctionComponent } from 'react'

import { Row } from '@tanstack/react-table'
import { get } from 'lodash-es'

import { AssignmentResponse, Contentmetadata, UiMetadata } from '@lyrahealth-inc/shared-app-logic'
import { BodyTextSize } from '@lyrahealth-inc/ui-core-crossplatform'

import { DataTable } from '../..'

interface ReportTableProps {
  metaData?: Contentmetadata
  data: AssignmentResponse[]
  rowClickHandler: (rowInfo: any) => void
  UIMetadata?: UiMetadata
  isClickable?: boolean
  stickyColumn?: string
  headerBackgroundColor?: string
}

type Column = {
  accessor: string
  Cell?: (props: any) => Element
  Header: string
  name?: string
  width?: number
  minWidth?: number
  moveToEnd?: boolean
}

export const ReportTable: FunctionComponent<ReportTableProps> = ({
  data,
  rowClickHandler,
  UIMetadata,
  metaData,
  isClickable,
  stickyColumn,
  headerBackgroundColor,
}) => {
  const columns: {
    id: string | undefined
    accessorKey: string
    header: string
    width?: number
    cell?: (props: any) => Element
  }[] = []
  const schemaProperties = get(metaData, 'schema.properties', [])
  const fixedColumns = get(UIMetadata, 'report.fixedColumns', [])
  const customColumns = get(UIMetadata, 'report.customColumns', [])
  const fields = Object.keys(get(data?.[0], 'response', []))

  customColumns.forEach((column: Column) => {
    const customColumnName = get(column, 'name')
    if (customColumnName && fields.includes(customColumnName)) {
      columns.push({
        id: column.name,
        accessorKey: column.accessor,
        header: column.Header.toUpperCase(),
        cell: column.Cell,
        ...(column.width && { width: column.width }),
      })
    }
  })

  for (const field of fields) {
    const Header = get(schemaProperties[field], 'shortTitle') || get(schemaProperties[field], 'title')
    const accessor = 'response.' + get(schemaProperties[field], 'name')
    const width = 'response.' + get(schemaProperties[field], 'width')

    // Prevent potential duplicates of custom columns
    if (Header && !customColumns.some((column: Column) => column.accessor.includes(accessor))) {
      columns.push({
        id: field,
        accessorKey: accessor,
        header: Header.toUpperCase(),
        ...(width && { width: parseInt(width) }),
      })
    }
  }

  fixedColumns.forEach((column: Column) =>
    column.moveToEnd
      ? columns.push({
          id: column.name,
          accessorKey: column.accessor,
          header: column.Header.toUpperCase(),
          cell: column.Cell,
          ...(column.width && { width: column.width }),
        })
      : columns.unshift({
          id: column.name,
          accessorKey: column.accessor,
          header: column.Header.toUpperCase(),
          cell: column.Cell,
          ...(column.width && { width: column.width }),
        }),
  )

  return (
    <DataTable
      data={data as any[]}
      columns={columns}
      pagination={false}
      onRowClick={(row: Row<Dict>) => rowClickHandler(row.original)}
      isClickable={isClickable}
      headerSize={BodyTextSize.CAPTION}
      rowHeight='48px'
      stickyColumn={stickyColumn}
      headerBackgroundColor={headerBackgroundColor}
    />
  )
}
