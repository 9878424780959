/* eslint-disable formatjs/no-literal-string-in-jsx */

import React, { FunctionComponent, useMemo, useState } from 'react'

import styled, { useTheme } from 'styled-components/native'

import {
  AIDraftStatus,
  ClientObject,
  getClientPreferredNameIfExists,
  getSectionHeaders,
  NoteMetadata,
  SchemaProperties,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, NotesBadge, Subhead } from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export type NoteSidebarProps = {
  completedNote: boolean
  sessionDate: string
  sessionNumber: string
  noteMetadata: NoteMetadata
  noteValues: {}
  summaryStatus?: AIDraftStatus
  clientDetails: ClientObject | null
}

interface SectionItemProps {
  theme: ThemeType
  selected: boolean
}

const Container = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  backgroundColor: colors.backgroundPrimary,
  padding: '15px',
  alignItems: 'start',
  width: '200px',
  borderRadius: '16px',
  borderWidth: '1px',
  borderColor: colors.borderDefault,
}))

const ClientInfoContainer = styled.View({
  marginBottom: '16px',
  alignItems: 'start',
})

const Separator = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  border: `0.5px solid ${colors.dividerPrimary}`,
  width: '200px',
  marginLeft: '-15px',
  marginBottom: '8px',
}))

const SubHeadContainer = styled(Subhead)({
  fontWeight: 400,
  fontSize: '16px',
})

const BodyTextContainer = styled(BodyText)({
  marginTop: '4px',
  marginBottom: '8px',
})

const SectionItemContainer = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SectionText = styled(BodyText)<SectionItemProps>`
  margin: 8px 0;
  font-weight: 500px;
  font-size: 16px;
  text-align: left;
  ${({ selected, theme }) =>
    selected &&
    `
    font-weight: 600px;
    color: ${theme.colors.textActive}; 
    background: ${theme.colors.backgroundHighlightPrimary}
    border-radius: ${theme.spacing['4px']}
  `}
`

const NotesBadgeContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginLeft: spacing['8px'],
}))

export const NotesSidebar: FunctionComponent<NoteSidebarProps> = ({
  completedNote,
  sessionDate,
  sessionNumber,
  noteMetadata,
  noteValues,
  summaryStatus,
  clientDetails,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const { colors } = useTheme()
  const { isPreferredNameEnabled } = useFlags()
  const shouldShowPreferredName = isPreferredNameEnabled && clientDetails?.employer === 'apple'
  const headers = useMemo(() => {
    const schemaProperties = noteMetadata.meta_data.schema.properties as unknown as SchemaProperties
    const headers = getSectionHeaders(noteMetadata, schemaProperties, noteValues)
    return headers.map(({ content, name, aiPromptEnabled }) => ({
      name,
      title: typeof content === 'string' ? content?.split('# ')[1] : '',
      aiPromptEnabled,
    }))
  }, [noteMetadata, noteValues])

  const sectionClicked = (i: number, headerName: string) => {
    setActiveIndex(i)
    document?.getElementById(headerName)?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
  }

  const displayAIBadge =
    summaryStatus &&
    [AIDraftStatus.COMPLETE, AIDraftStatus.IN_PROGRESS, AIDraftStatus.NOT_STARTED].includes(summaryStatus) &&
    !completedNote

  return (
    <Container testID={tID('NotesSidebar')}>
      <ClientInfoContainer>
        <SubHeadContainer
          size={SubheadSize.SMALL}
          text={shouldShowPreferredName ? 'Legal Name' : 'Client Name'}
          textAlign='center'
          color={colors.textInactive}
        />
        <BodyTextContainer
          size={BodyTextSize.DEFAULT}
          text={clientDetails?.full_name}
          textAlign='center'
          testID={tID('NotesSidebar-client-name')}
        />
        {shouldShowPreferredName && (
          <>
            <SubHeadContainer size={SubheadSize.SMALL} text={'Preferred Name'} color={colors.textInactive} />
            <BodyTextContainer
              size={BodyTextSize.DEFAULT}
              text={getClientPreferredNameIfExists(clientDetails)}
              testID={tID('NotesSidebar-client-name')}
            />
          </>
        )}
        {sessionDate && sessionNumber && (
          <>
            <SubHeadContainer
              size={SubheadSize.SMALL}
              text={'Session Date'}
              textAlign='center'
              color={colors.textInactive}
            />
            <BodyTextContainer size={BodyTextSize.DEFAULT} text={sessionDate} textAlign='center' />
            <SubHeadContainer
              size={SubheadSize.SMALL}
              text={'Session Number'}
              textAlign='center'
              color={colors.textInactive}
            />
            <BodyTextContainer
              size={BodyTextSize.DEFAULT}
              text={sessionNumber}
              textAlign='center'
              testID={tID('NotesSidebar-session-number')}
            />
          </>
        )}
      </ClientInfoContainer>
      <Separator />
      {headers.map((header, i) => {
        return (
          <SectionItemContainer key={i}>
            <SectionText
              size={BodyTextSize.SMALL}
              text={header.title}
              textAlign='center'
              selected={activeIndex === i}
              onPress={() => sectionClicked(i, header.name || '')}
              testID={tID(`NotesSidebar-${header.name}`)}
            />
            {displayAIBadge && header?.aiPromptEnabled && (
              <NotesBadgeContainer testID={tID(`NotesSidebar-${header.name}-aiPromptBadge`)}>
                <NotesBadge hideText status={summaryStatus} />
              </NotesBadgeContainer>
            )}
          </SectionItemContainer>
        )
      })}
    </Container>
  )
}
