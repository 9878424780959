import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'

import { cloneDeep, isEmpty, isUndefined } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  AssignmentResponse,
  AssignmentStatuses,
  Contentmetadata,
  FieldSchema,
  Response,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Image, Subhead } from '../../atoms'
import { ReportTable } from '../../molecules'
import { BodyTextSize, colors as deprecatedColors, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type Column = {
  accessor: string
  Cell: (props: any) => React.JSX.Element
  Header: string
  name?: string
  width?: number
}

type ExerciseResponseTableProps = {
  data: AssignmentResponse[]
  name: string
  metaData?: Contentmetadata
  rowClickHandler: (response: AssignmentResponse) => void
  UIMetadata: {
    report: {
      customColumns: Column[]
      fixedColumns: Column[]
    }
  }
}

const ExerciseResponseTableContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  width: '100%',
  marginTop: spacing['24px'],
}))

const ExerciseResponseTableFilterContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: `${spacing['24px']}`,
}))

const ImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  width: '320px',
  height: '192px',
  marginTop: spacing['12px'],
}))

const ExerciseResponseTableEmptyResponseContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: `${spacing['32px']} ${spacing['24px']}`,
  backgroundColor: deprecatedColors.charcoal1,
  borderRadius: spacing['4px'],
}))

const ReportTableContainer = styled(View)<{ theme: ThemeType }>(({ theme: { spacing, colors } }) => ({
  padding: spacing['32px'],
  borderRadius: spacing['16px'],
  boxShadow: `0 2px 10px ${colors.shadowLow}`,
}))

export const ExerciseResponseTable: React.FC<ExerciseResponseTableProps> = ({
  data,
  metaData,
  rowClickHandler,
  UIMetadata,
}) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  const submissions = useMemo(() => {
    return cloneDeep(data)
      .filter((response: Response) => response.status === AssignmentStatuses.completed)
      .sort((a: Response, b: Response) => {
        return new Date(b.submit_date).getTime() - new Date(a.submit_date).getTime()
      })
  }, [data])

  const renderTables = (entries: AssignmentResponse[]) => {
    const dataRecordKeys = Object.values(metaData?.schema?.properties || {})
      .filter((property: FieldSchema) => {
        return (
          !(property?.show === false) && (isUndefined(property?.hideTableColumn) || property?.hideTableColumn !== true)
        )
      })
      .map((property: FieldSchema) => property?.name)

    // This will mutate the assignmentResponse.response
    const dataRecords = entries.map((assignmentResponse: AssignmentResponse) => {
      const newResponse = {}
      dataRecordKeys.forEach((key: string) => {
        newResponse[key] = assignmentResponse.response[key]
      })
      if (!isEmpty(newResponse)) {
        assignmentResponse.response = newResponse
      }
      return assignmentResponse
    })

    return (
      <ExerciseResponseTableContainer>
        <ExerciseResponseTableFilterContainer>
          <Subhead
            size={SubheadSize.LARGE}
            text={<FormattedMessage defaultMessage='Submitted' description='List of submissions' />}
          />
          <BodyText
            size={BodyTextSize.LARGE}
            text={
              <FormattedMessage
                defaultMessage='{count, plural,
                one {1 entry}
                other {{count} entries}
              }'
                description='Number of entries'
                values={{ count: entries.length }}
              />
            }
          />
        </ExerciseResponseTableFilterContainer>
        {dataRecords.length === 0 ? (
          <BodyText
            text={<FormattedMessage defaultMessage='No Data' description='There is no data to be displayed' />}
            style={{ textAlign: 'center' }}
          />
        ) : (
          <ReportTableContainer>
            <ReportTable
              metaData={metaData}
              data={dataRecords}
              rowClickHandler={rowClickHandler}
              UIMetadata={UIMetadata}
              isClickable={true}
              stickyColumn='date'
              headerBackgroundColor={colors.backgroundSecondary}
            />
          </ReportTableContainer>
        )}
      </ExerciseResponseTableContainer>
    )
  }

  if (isEmpty(submissions))
    return (
      <ExerciseResponseTableEmptyResponseContainer>
        <BodyText
          size={BodyTextSize.DEFAULT}
          text={
            <FormattedMessage
              defaultMessage='Submitted entries will show up here'
              description='Inform user this is where entries will appear'
            />
          }
          color={deprecatedColors.charcoal6}
        />

        <ImageContainer
          source={require('./../../assets/Activity_Empty_Draft_State.png')}
          accessibilityLabel={formatMessage({
            defaultMessage: 'Response list is empty',
            description: 'There are no responses.',
          })}
          aria-label={formatMessage({
            defaultMessage: 'Response list is empty',
            description: 'There are no responses.',
          })}
        />
      </ExerciseResponseTableEmptyResponseContainer>
    )
  return <ExerciseResponseTableContainer>{renderTables(submissions)}</ExerciseResponseTableContainer>
}
