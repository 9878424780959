import React, { Component } from 'react'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'

import { format, parseISO } from 'date-fns'
import numeral from 'numeral'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form/immutable'

import { CardIcon, CheckIcon, ExclamationIcon, MinusIcon, SecondaryButton, WarningIcon } from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './paymentCard.module.scss'
import { PROVIDERS_DETAILS_PAYMENT_HISTORY } from '../../../common/constants/routingConstants'
import * as alertActions from '../../../data/alertActions'
import { getAuthRoles } from '../../../data/auth/authSelectors'
import store, { RootState } from '../../../data/store'
import * as paymentDataActions from '../../../payments/data/paymentsDataActions'
import withRouter from '../../../routing/withRouter'
import { ROLES, stripeUrl } from '../../constants/appConstants'
import { hasRole } from '../../utils/utils'
import { DifferentialPricingSessionTypes } from '../differentialPricingSessionTypes/DifferentialPricingSessionTypes'

type OwnPaymentCardProps = {
  data?: any
  viewClick: (...args: any[]) => any
  reverseClick: (...args: any[]) => any
  rejectClick: (...args: any[]) => any
  excludeClick: (...args: any[]) => any
  actions?: any
  type?: string
  router?: any
  conflicts?: any[]
  readonly?: boolean
  roles?: any[]
}

type PaymentCardState = any

type PaymentCardProps = OwnPaymentCardProps & typeof PaymentCard.defaultProps

class PaymentCard extends Component<PaymentCardProps, PaymentCardState> {
  static defaultProps = {
    type: 'provider',
    conflicts: [],
  }

  constructor(props: PaymentCardProps) {
    super(props)

    this.state = {
      approvePending: false,
      rejectPending: false,
      reversePending: false,
      excludePending: false,
      overridePending: false,
    }
  }

  _prepUpdatePayload = (status: any) => {
    const selector = formValueSelector(`chargeComment_${this.props.data.id}`)
    return {
      status: status,
      note: selector(store.getState(), 'comment'),
      eligibility_status: this.props.data.eligibilityInfo[0],
      eligibility_code: this.props.data.eligibilityInfo[2],
    }
  }

  _updateCharge = (status: any) => {
    const stateObj = {}
    stateObj[`${status}Pending`] = true
    this.setState(stateObj)
    this.props.actions
      .updateChargeStatus(this.props.data.id, this._prepUpdatePayload(status), this.props.data.is_too_frequent)
      .then(
        () => {
          stateObj[`${status}Pending`] = false
          this.setState(stateObj)
        },
        () => {
          stateObj[`${status}Pending`] = false
          this.setState(stateObj)
        },
      )
  }

  _onViewClick = () => {
    this.props.viewClick(this.props.data)
  }

  onClickActionButton = (e: any) => {
    e.stopPropagation()
    if (e.target.value === 'reverse') {
      const reverseClickData = {
        chargeId: this.props.data.id,
        is_too_frequent: this.props.data.is_too_frequent,
        admin_note: this.props.data.admin_note,
        payload: this._prepUpdatePayload('reverse'),
      }
      this.props.reverseClick(reverseClickData)
    } else if (e.target.value === 'reject') {
      const rejectClickData = {
        chargeId: this.props.data.id,
        is_too_frequent: this.props.data.is_too_frequent,
        admin_note: this.props.data.admin_note,
        payload: this._prepUpdatePayload('reject'),
      }
      this.props.rejectClick(rejectClickData)
    } else if (e.target.value === 'exclude') {
      const excludeClickData = {
        chargeId: this.props.data.id,
        is_too_frequent: this.props.data.is_too_frequent,
        admin_note: this.props.data.admin_note,
        payload: this._prepUpdatePayload('exclude'),
      }
      this.props.excludeClick(excludeClickData)
    } else {
      this._updateCharge(e.target.value)
    }
  }

  _configureIcon = () => {
    if (['submitted', 'pending'].includes(this.props.data.status.toLowerCase())) {
      return (
        <span styleName='icon-container'>
          {this.props.data.eligibilityInfo[0] ? (
            <MinusIcon fillColor='lightgreen' isFilled width='15' />
          ) : (
            <ExclamationIcon fillColor='red' isFilled width='15' />
          )}
        </span>
      )
    }
    return false
  }

  _showPracticeName = () => {
    if (!this.props.data.practice_name) return false

    return <div styleName='p-addition'>{this.props.data.practice_name}</div>
  }

  _showChargeStatus = () => {
    if (['submitted', 'pending'].includes(this.props.data.status.toLowerCase())) return false

    return (
      <div styleName='details'>
        <h4>Charge Status</h4>
        <p>{this.props.data.status}</p>
      </div>
    )
  }

  renderActionButton = () => {
    if (this.props.readonly) return false
    let excludeButton
    if (!this.props.data.exclude_from_session_count && !this.props.data.is_health_plan) {
      excludeButton = (
        <SecondaryButton
          styleType='loader-within'
          isLoading={this.state.excludePending}
          disabled={this.state.excludePending}
          value='exclude'
          onClick={this.onClickActionButton}
        >
          Exclude
        </SecondaryButton>
      )
    }
    switch (this.props.data.status.toLowerCase()) {
      case 'submitted':
        if (this.props.data.hasUpdatedTo) {
          const hasUpdatedTo = this.props.data.hasUpdatedTo.toLowerCase()
          let icon
          if (hasUpdatedTo === 'approved') icon = <CheckIcon isFilled width={20} fillColor={styles.x_success} />
          if (hasUpdatedTo === 'rejected') icon = <MinusIcon isFilled width={20} fillColor={styles.x_highlight} />
          return (
            <div styleName={`status-label ${hasUpdatedTo}`}>
              {icon}
              <h3>{hasUpdatedTo}</h3>
            </div>
          )
        }
        return (
          <div>
            <SecondaryButton
              styleType='loader-within'
              isLoading={this.state.approvePending}
              disabled={this.state.rejectPending}
              value='approve'
              onClick={this.onClickActionButton}
            >
              Approve
            </SecondaryButton>
            <SecondaryButton
              styleType='loader-within'
              isLoading={this.state.rejectPending}
              disabled={this.state.approvePending}
              value='reject'
              onClick={this.onClickActionButton}
            >
              Reject
            </SecondaryButton>
          </div>
        )
      case 'approved':
        if (this.props.data.hasUpdatedTo) {
          const hasUpdatedTo = this.props.data.hasUpdatedTo.toLowerCase()
          if (hasUpdatedTo === 'reversed') {
            return (
              <div styleName={`status-label ${hasUpdatedTo}`}>
                {hasUpdatedTo === 'reversed' ? <MinusIcon isFilled width={20} fillColor={styles.x_highlight} /> : ''}
                <h3>{hasUpdatedTo}</h3>
              </div>
            )
          }
        }
        const reverseButton = (
          <SecondaryButton
            styleType='loader-within'
            isLoading={this.state.reversePending}
            disabled={this.state.reversePending}
            value='reverse'
            onClick={this.onClickActionButton}
          >
            Reverse
          </SecondaryButton>
        )
        return (
          <div>
            {excludeButton}
            {reverseButton}
          </div>
        )
      case 'pending':
        return (
          <SecondaryButton
            styleType='loader-within'
            isLoading={this.state.overridePending}
            disabled={this.state.overridePending}
            value='override'
            onClick={this.onClickActionButton}
          >
            Override
          </SecondaryButton>
        )
      case 't0_sessions':
        return <div>{excludeButton}</div>
      default:
        return false
    }
  }

  _showCommentBox = () => {
    const { data } = this.props

    return (
      <div styleName='comment-container'>
        <p>
          <b>Admin comment: </b>
          {data.admin_note}
        </p>
      </div>
    )
  }

  _gotoProvider = (id: any) => {
    this.props.router.navigate(PROVIDERS_DETAILS_PAYMENT_HISTORY.route, {
      state: { provider_id: id, fromPaymentCard: true },
    })
  }

  _showSessionDetail = () => {
    if (!this.props.data.other_session_description) return false
    return (
      <div styleName='details details-session'>
        <h4>Session Description</h4>
        <p>{this.props.data.other_session_description}</p>
      </div>
    )
  }

  _showMissingHPInfo = (data: any) => {
    if (data.is_health_plan) {
      return data.icd10_primary_diagnosis ? (
        <div styleName='hp-icon'>
          <CardIcon fillColor={styles.x_teal5} isFilled width={30} />
        </div>
      ) : (
        <div styleName='healthplan-info '>
          <WarningIcon fillColor={styles.x_red5} isFilled width={20} />
          <p styleName='missing-icd-10'> ICD-10 missing</p>
          <div styleName='hp-icon'>
            <CardIcon fillColor={styles.x_teal5} isFilled width={30} />
          </div>
        </div>
      )
    }
    return null
  }

  render() {
    const { data, roles } = this.props
    const referenceNumber = data.reference_number ? `#${data.reference_number}` : null // TODO: make link OR text button?
    const stripeDashboardUrl = `${stripeUrl}${data.transfer_id}`
    const patientEligibility = data.eligibilityInfo[0] || data.eligibilityInfo[1]
    return (
      <div
        styleName='payment-card'
        data-test-id='payment-card'
        onClick={() => {
          this._onViewClick()
        }}
        onKeyDown={() => {}}
        role='none'
      >
        <div styleName='overview-container'>
          <div styleName='left'>
            {/* @ts-expect-error TS(2554): Expected 0 arguments, but got 1. */}
            {this._configureIcon(data)}
            <h3>
              {this.props.type === 'patient' ? (
                <span style={{ textTransform: 'capitalize' }}>
                  {data.first_name} {data.last_name}
                </span>
              ) : (
                <span
                  className={styles['clickable-style']}
                  style={{ textTransform: 'capitalize' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    this._gotoProvider(data.provider_lyra_id)
                  }}
                  onKeyDown={() => {}}
                  role='link'
                  tabIndex={0}
                >
                  {data.provider_first_name} {data.provider_last_name}
                </span>
              )}
            </h3>
            <p>{format(parseISO(data.visit_date), 'MM/dd/yyyy')}</p>
            {/* @ts-expect-error TS(2554): Expected 0 arguments, but got 1. */}
            {this._showPracticeName(data)}
            <div styleName='p-addition'>
              <a href={stripeDashboardUrl} target='_blank' onClick={(e) => e.stopPropagation()} rel='noreferrer'>
                {referenceNumber}
              </a>
            </div>
          </div>

          <div styleName='right'>
            <p styleName='error-message'>{data.updateError}</p>
            {this._showMissingHPInfo(data)}
            <div styleName='actions'>{this.renderActionButton()}</div>
            <h2 styleName='price'>{numeral(data.session_rate / 100).format('$0,0[.]00')}</h2>
          </div>

          <div styleName='bottom'>
            <div styleName='details-container' onClick={(e) => e.stopPropagation()} onKeyDown={() => {}} role='none'>
              {this.props.type === 'provider' && (
                <div styleName='details'>
                  <h4>Patient Name</h4>
                  <p style={{ textTransform: 'capitalize' }}>
                    {data.first_name} {data.last_name}
                  </p>
                </div>
              )}
              <div styleName='details details-eligibility'>
                <h4>Eligible</h4>
                <p style={{ textTransform: 'capitalize' }}>{patientEligibility.toString()}</p>
              </div>
              <div styleName='details'>
                <h4>Eligible Member</h4>
                <p style={{ textTransform: 'capitalize' }}>
                  {data.eligible_member_first_name} {data.eligible_member_last_name}
                </p>
              </div>
              {/* @ts-expect-error TS(2554): Expected 0 arguments, but got 1. */}
              {this._showChargeStatus(data)}
              <div styleName='details details-session'>
                <h4>
                  Session Type
                  {data.session_type.toLowerCase() === 'other' && <i styleName='indicator-dot' />}
                </h4>
                <DifferentialPricingSessionTypes sessionType={data.session_type} />
              </div>
              {hasRole(roles, ROLES.PAYMENTS_ADMIN) && (
                <div styleName='details details-session'>
                  <h4>Submission Date</h4>
                  <p>{format(parseISO(data.created_at), 'MM/dd/yyyy')}</p>
                </div>
              )}
              {/* @ts-expect-error TS(2554): Expected 0 arguments, but got 1. */}
              {this._showSessionDetail(data)}
              {this.props.conflicts.length > 0 && (
                <div styleName='details details-conflicts'>
                  <h4>
                    Same Day
                    {['submitted', 'pending'].includes(data.status.toLowerCase()) && <i styleName='indicator-dot' />}
                  </h4>
                  <p>
                    {this.props.conflicts.length > 2
                      ? this.props.conflicts.slice(0, 2).join(', ') + ' ...'
                      : this.props.conflicts.join(', ')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* @ts-expect-error TS(2554): Expected 0 arguments, but got 1. */}
        {data.admin_note ? this._showCommentBox(data) : ''}
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    roles: getAuthRoles(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ ...paymentDataActions, ...alertActions }, dispatch),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(toJS(CSSModules(PaymentCard, styles, { allowMultiple: true }))),
)
