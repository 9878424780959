import React, { ReactElement, useState } from 'react'
import {
  Typeahead,
  TypeaheadLabelKey,
  TypeaheadMenuProps,
  TypeaheadModel,
  TypeaheadResult,
} from 'react-bootstrap-typeahead'
import { FieldInputProps, FieldMetaState } from 'react-final-form'

import { Placement } from '@popperjs/core'
import classNames from 'classnames'
import { Iterable } from 'immutable'
import { noop } from 'lodash-es'

import styles from './typeAheadField.module.scss'
import Tooltip, { TOOLTIP_PLACEMENTS } from '../../tooltip/Tooltip'
import BaseInput from '../baseInput/BaseInput'

const TypeAheadField: React.FC<TypeAheadFieldProps> = ({
  onChange = noop,
  input,
  options,
  label,
  meta: { touched, error, submitError } = {},
  className,
  defaultSelected,
  disabled,
  readOnly,
  alwaysOpen,
  multiSelect = true,
  placeholder,
  id,
  filterBy,
  labelKey,
  shouldForceTouch = false,
  tooltipContent,
  tooltipIcon,
  tooltipPlacement = TOOLTIP_PLACEMENTS.TOP,
  tooltipStyle,
  tooltipTextWidth,
  renderMenuItemChildren,
}) => {
  const fieldInfoId = `${input.name}_info`

  const [showError, setShowError] = useState<boolean>(false)

  const handleChange = (value: any) => {
    onChange(value)
    if (input.onChange) input.onChange(value)
  }
  const handleBlur = () => {
    if (shouldForceTouch && !showError) {
      setShowError(true)
    }
  }
  // TODO: Remove this check once we fully get rid of usages of the field with `redux-form/immutable`
  if (Iterable.isIterable(input.value)) {
    input.value = input.value.toJS()
  }
  return (
    <BaseInput
      className={className}
      label={label}
      touched={touched || showError}
      error={error || submitError}
      disabled={disabled}
      readOnly={readOnly}
      htmlFor={input.name}
      fieldInfoId={fieldInfoId}
      dataTestId={`TypeAhead-${input.name}`}
      toolTip={
        tooltipContent ? (
          <Tooltip
            content={tooltipContent}
            id={`${input.name}_tooltip`}
            triggerStyle={tooltipStyle}
            placement={tooltipPlacement}
            customWidth={tooltipTextWidth}
          >
            {tooltipIcon}
          </Tooltip>
        ) : null
      }
    >
      <Typeahead
        id={id ?? input.name}
        multiple={multiSelect}
        defaultOpen={alwaysOpen ? true : undefined}
        open={alwaysOpen ? true : undefined}
        placeholder={placeholder}
        inputProps={{
          name: input.name,
          id: input.name,
          value: input.value,
          'aria-describedby': fieldInfoId,
        }}
        defaultSelected={defaultSelected}
        selected={input.value}
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        disabled={disabled ?? readOnly}
        filterBy={filterBy}
        labelKey={labelKey}
        // @ts-expect-error TS(2769): No overload matches this call.
        renderMenuItemChildren={renderMenuItemChildren}
        // ignore TS error because the `Typeahead` component itself mistakenly omits `className` in its propTypes
        // (hence it is absent in the types from DefinitelyTyped ), although it is used.
        className={classNames(
          { [styles['always-open']]: alwaysOpen },
          touched && error ? styles['typeahead-invalid'] : styles.typeahead,
          { [styles['read-only']]: readOnly },
        )}
      />
    </BaseInput>
  )
}

type TypeAheadFieldProps = {
  id?: string
  onChange?: () => void
  disabled?: boolean
  readOnly?: boolean
  options: any
  placeholder?: string
  multiSelect?: boolean
  meta?: FieldMetaState<any>
  input: Partial<FieldInputProps<any, any>>
  label?: string
  emptyLabel?: string
  className?: string
  alwaysOpen?: boolean
  defaultSelected: any
  filterBy: string[] | (() => boolean)
  labelKey?: TypeaheadLabelKey<TypeaheadModel>
  shouldForceTouch: boolean
  tooltipContent?: string | ReactElement
  tooltipIcon?: ReactElement
  tooltipPlacement?: Placement
  tooltipStyle?: Dict
  tooltipTextWidth?: string
  renderMenuItemChildren: (
    option: TypeaheadResult<TypeaheadModel>,
    props: TypeaheadMenuProps<TypeaheadModel>,
    index: number,
  ) => React.ReactNode
}

export default TypeAheadField
