import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'

import * as _ from 'lodash-es'
import { Field } from 'redux-form/immutable'
import { useTheme } from 'styled-components/native'

import {
  Address,
  CustomerInfo,
  overrideCompanyNames,
  ProviderAdminProviderInfo,
  ProviderRates,
  ProviderUser,
  useFlags,
  UserInfo,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BaseModal,
  Checkbox,
  DateInputField,
  ExclamationCircleIcon,
  InputField,
  MaskedInputField,
  PrimaryButton,
  RadioGroup,
  SelectField,
  Tooltip,
  TypeAheadField,
} from '@lyrahealth-inc/ui-core'
import { BodyText, InfoIcon, InputFieldRFF, TOOLTIP_TRIGGER_ICON } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './paymentRequestForm.module.scss'
import BlockPaymentCard from '../../../../requestPayment/blockPayment/BlockPaymentCard'
import {
  attendanceOptions,
  companiesWithWarnings,
  companyMessages,
  customerExclusionList,
  diagnoses,
  diffPricingSessionTypesDisplay,
  ebtTypes,
  getConditions,
  modalityOptions,
  PAYMENT_FORM,
  ROLES,
  sessionTypesDisplay,
  severityOptions,
} from '../../../constants/appConstants'
import { hasRole, isECDCProvider, trimInitialWhiteSpace } from '../../../utils/utils'
import LyraCodeExplanationModalContent from '../../lyraCode/LyraCodeExplanationModalContent'
import PaymentAdditionalInfoContent from '../paymentInfo/PaymentAdditionalInfoContent'

const initialEvaluationSessionTypes = [
  'individual_initial_evaluation',
  'couples_initial_evaluation',
  'family_initial_evaluation',
  'group_initial_evaluation',
  'child_initial_evaluation',
]

const PaymentRequestForm: React.FC<PaymentRequestFormProps> = ({
  attendance,
  disabledFields,
  handleSubmit,
  submitting,
  submitFunction,
  primaryButtonText,
  providers,
  showEligibleMemberDetails,
  sessionType,
  showDiagnosisDescription,
  clientOutcomesEligibility,
  providerChangeHandler,
  showOutcomesMessage,
  patientOutcomesAgreed,
  initialCompany,
  initialDiagnoses,
  initialModality,
  lastSessionHandler,
  sponsoringCompanies,
  guaranteedTime,
  guaranteedTimeEligible,
  selectedCompany,
  lyraCodeValidationUser,
  disableSubmitButton,
  showLyraCode,
  hideDependentField,
  selectedProvider,
  loggedInUser,
  config,
  dateInputKey,
  providerAddresses,
  locationChangeHandler,
  primaryCondition,
  secondaryCondition,
  ebtType,
  rates,
}) => {
  const { isECDCEnabled, differentialPricingRatesEnabled, shouldReplaceWalkmeTooltips } = useFlags()
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null)
  const [currentModality, setCurrentModality] = useState<string | undefined>('')
  const [addresses, setAddresses] = useState<Address[]>([])
  const [companyMessage, setCompanyMessage] = useState<React.ReactNode | null>(null)
  const [sessionDropDownOptions, setSessionDropDownOptions] = useState<React.ReactNode | null>(null)
  const [displayedModalityOptions, setDisplayedModalityOptions] = useState<React.ReactNode | null>(null)
  const conditionsList = useMemo(() => getConditions({}), [])

  const { colors } = useTheme()

  const emailLink = <a href='mailto:providers@lyrahealth.com'>providers@lyrahealth.com</a>
  const helpCenterLink = (
    <a href='https://provider-support.lyrahealth.com/hc/en-us/requests/new?ticket_form_id=538748'>Help Center</a>
  )
  const addressFormLink = (
    <a
      href='https://provider-support.lyrahealth.com/hc/en-us/requests/new?ticket_form_id=360000124287'
      target='_blank'
      rel='noreferrer'
    >
      Address Update Form
    </a>
  )
  const sponsoringCompanyLink = (
    <a
      href='https://provider-support.lyrahealth.com/hc/en-us/categories/4402698962707-Sponsoring-Company-Session-Limits-and-Policies'
      target='_blank'
      rel='noreferrer'
    >
      here
    </a>
  )

  const nuclearBadgedLink = {
    nextera: 'https://provider-support.lyrahealth.com/hc/en-us/articles/4413021102099-NextEra-Energy',
    aps: 'https://provider-support.lyrahealth.com/hc/en-us/articles/7926599714451',
  }

  const companies = useMemo(
    () =>
      sponsoringCompanies
        ?.filter((company: CustomerInfo) => !customerExclusionList.includes(company.value))
        .map((company) => {
          const newCompany = _.cloneDeep(company)
          if (newCompany.value in overrideCompanyNames) {
            newCompany.label = overrideCompanyNames[newCompany.value]
          }
          return newCompany
        }),
    [sponsoringCompanies],
  )

  const customerAliases = useMemo(() => {
    const aliases: CustomerInfo[] = []
    companies.forEach((company) => {
      company.customer_aliases?.forEach((customerAlias: string) => {
        aliases.push({ ...company, label: customerAlias })
      })
    })
    return aliases
  }, [companies])
  useEffect(() => {
    setCurrentModality(initialModality)
  }, [initialModality])

  useEffect(() => {
    // Session types for selected company
    const differentialCheckedSessions = differentialPricingRatesEnabled
      ? diffPricingSessionTypesDisplay
      : sessionTypesDisplay
    let selectedSessionTypes = config.allSessionTypes
      ? differentialCheckedSessions
      : selectedCompany?.[0]?.customer_session_type ?? differentialCheckedSessions
    if (differentialPricingRatesEnabled && rates && !config.allSessionTypes) {
      // If Differential rates flag is true, we filter out session types the chosen provider does not have rates set for
      // as long as the logged-in user is not a payment admin.
      selectedSessionTypes = selectedSessionTypes.filter(
        (element: { value: string; label: string }) =>
          rates.hasOwnProperty(element.value) || element.value.toLowerCase() == 'other',
      )
    }
    setSessionDropDownOptions(
      selectedSessionTypes.map((element: { value: string; label: string }, i: number) => (
        <option key={i} value={element.value}>
          {element.label}
        </option>
      )),
    )
  }, [config, differentialPricingRatesEnabled, rates, selectedCompany])

  useEffect(() => {
    if (providerAddresses && providerAddresses.length > 0) {
      setAddresses(providerAddresses)
      setDisplayedModalityOptions(
        Object.entries(modalityOptions).map((modality, i) => {
          if (modality[0] === 'in_person' && addresses && addresses.length === 1) {
            const address = `${addresses[0].street1}`
            return <option key={i} value={modality[0]}>{`${modality[1]} - ${address}`}</option>
          } else {
            return (
              <option key={i} value={modality[0]}>
                {modality[1]}
              </option>
            )
          }
        }),
      )
    } else {
      setDisplayedModalityOptions(
        Object.entries(modalityOptions).map((modality, i) => {
          return (
            <option key={i} value={modality[0]}>
              {modality[1]}
            </option>
          )
        }),
      )
    }
  }, [addresses, providerAddresses])

  const onHandleSubmit = () => {
    if (submitting) {
      handleSubmit(submitFunction)
      if (currentModality) {
        setCurrentModality('')
      }
    }
  }

  const referenceNumberField = shouldReplaceWalkmeTooltips ? (
    <Field
      name={PAYMENT_FORM.FIELDS.REFERENCE_NUMBER}
      component={InputFieldRFF}
      label='Reference Number'
      placeholder='Reference Number'
      toolTipContent={
        <div className={styles['tooltip-text']}>
          This is an optional field which allows you to track payments if you use an internal invoicing system. If you
          leave this blank, the system will generate a reference number for the invoice after it has been approved.
        </div>
      }
      toolTipTriggerConfig={{ icon: TOOLTIP_TRIGGER_ICON.INFO }}
      toolTipHoverEnabled={true}
    />
  ) : (
    <Field
      name={PAYMENT_FORM.FIELDS.REFERENCE_NUMBER}
      type='text'
      component={InputField}
      label='Reference Number'
      placeholder='Reference Number'
    />
  )

  const getSponsoringCompanyMessage = (companyName: string) => {
    return companiesWithWarnings[companyName]?.map((warning: string) => {
      switch (warning) {
        case companyMessages.MULTIPLE_INSTANCES:
          return (
            <p className={styles['company-message']}>
              <ExclamationCircleIcon fillColor={styles.x_highlight} />
              <p className={styles['message-text']} data-test-id='PaymentRequestForm-multipleInstancesMessage'>
                Make sure you&apos;ve selected the right company name. Some companies have multiple accounts to cater to
                different patient needs. Ask your patient if you&apos;re not sure which company account they belong to.
              </p>
            </p>
          )
        case companyMessages.NUCLEAR_BADGED:
          return (
            <p className={styles['company-message']}>
              <ExclamationCircleIcon fillColor={styles.x_highlight} />
              <p className={styles['message-text']} data-test-id='PaymentRequestForm-nuclearBadgedMessage'>
                Ask your patient if they are nuclear badged. There are special considerations to take for nuclear badged
                patients. <a href={nuclearBadgedLink[companyName]}>View nuclear access help center article</a>
              </p>
            </p>
          )
        default:
          return null
      }
    })
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit(submitFunction)}>
      <Field name={PAYMENT_FORM.FIELDS.PRACTICE_ID} type='hidden' component={InputField} />
      <h2 data-test-id='PaymentRequestForm-title'>Patient Details</h2>
      {providers.length == 0 && hasRole(loggedInUser.roles, ROLES.PAYMENTS_ADMIN) ? (
        <Field name={PAYMENT_FORM.FIELDS.PROVIDER_LYRA_ID} type='hidden' component={InputField} />
      ) : (
        <div className={`row ${styles['select-provider']}`}>
          <div className='col-xs-12 col-sm-6'>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Field
              name={PAYMENT_FORM.FIELDS.PROVIDER_LYRA_ID}
              onChange={providerChangeHandler}
              component={SelectField}
              label='Rendering Provider *'
              subLabel={
                <div>
                  <div className={styles['sub-label']}>
                    If the correct rendering provider is not listed in the dropdown, please contact {emailLink} prior to
                    submitting the payment request.
                  </div>
                </div>
              }
            >
              <option hidden value=''>
                Select
              </option>
              {providers.length > 0 ? (
                providers.map((provider, idx) => (
                  <option key={`providerOption_${idx}`} value={provider.lyra_id}>
                    {provider.full_name}
                  </option>
                ))
              ) : (
                <option key={`providerOption_1`} value={loggedInUser.id}>
                  {loggedInUser.first_name} {loggedInUser.last_name}
                </option>
              )}
            </Field>
          </div>
        </div>
      )}
      {guaranteedTimeEligible ? (
        <Field name={PAYMENT_FORM.FIELDS.IS_GUARANTEED_TIME} component={Checkbox} type='checkbox'>
          Submit as &apos;Unused Session Slot-No client&apos;
        </Field>
      ) : (
        []
      )}
      <div className={styles['request-payment-block-card']}>
        <div className='row'>
          <BlockPaymentCard loggedInUser={loggedInUser} selectedProvider={selectedProvider} />
          <div data-test-id='PaymentRequestForm-companiesTypeAhead'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name={PAYMENT_FORM.FIELDS.COMPANY}
                defaultSelected={initialCompany}
                component={TypeAheadField}
                forceMatch
                options={[...companies, ...customerAliases]}
                multiSelect={false}
                disabled={disabledFields.company}
                label='Sponsoring Company *'
                placeholder='Sponsoring Company'
                shouldForceTouch
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  // check if company requires a special message to be displayed for DA providers only
                  if (e[0]?.value) {
                    setCompanyMessage(getSponsoringCompanyMessage(e[0].value))
                  }
                }}
                tooltipContent={
                  shouldReplaceWalkmeTooltips && (
                    <div>
                      Select the company from which the client is receiving benefits. If the client is a dependent,
                      please make sure that they are providing you with the sponsoring company of the eligible employee.
                      For information on our current sponsoring companies and their session limits please click{' '}
                      {sponsoringCompanyLink}.
                    </div>
                  )
                }
                tooltipIcon={<InfoIcon size={16} />}
                tooltipTextWidth='350px'
              />
            </div>
            {companyMessage && <div className={styles['company-message-container']}>{companyMessage}</div>}
          </div>
        </div>

        {showLyraCode ? (
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name={PAYMENT_FORM.FIELDS.LYRA_CODE}
                type='text'
                autocomplete='off'
                component={MaskedInputField}
                mask='###-###-###'
                placeholder='888-888-888'
                label={`Lyra code ${_.get(selectedCompany, '[0].lyra_code_required') === 'true' ? '*' : ''}`}
                indicateAsyncValidity
                asyncValid={!_.isEmpty(lyraCodeValidationUser)}
                hintContent={
                  <span
                    onClick={() =>
                      setModalContent(<LyraCodeExplanationModalContent closeModal={() => setModalContent(null)} />)
                    }
                    onKeyDown={() => {}}
                    className={styles['lyra-code-explanation']}
                    role='button'
                    tabIndex={0}
                  >
                    Where can I find this?
                  </span>
                }
              />
            </div>
          </div>
        ) : null}

        {!hideDependentField && (
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name={PAYMENT_FORM.FIELDS.RELATIONSHIP}
                label='Is the patient a dependent *'
                component={RadioGroup}
                styleType='stroked'
                isStacked={false}
                disabled={disabledFields.attendance}
                buttons={[
                  { text: 'Yes', value: 'dependent' },
                  { text: 'No', value: 'employee' },
                ]}
              />
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name={PAYMENT_FORM.FIELDS.DATE_OF_BIRTH}
              type='tel'
              component={MaskedInputField}
              mask='11/11/1111'
              placeholder='MM/DD/YYYY'
              label='Patient Date of Birth *'
              disabled={disabledFields.date_of_birth}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name={PAYMENT_FORM.FIELDS.FIRST_NAME}
              type='text'
              component={InputField}
              label='Patient First Name *'
              placeholder='First Name'
              disabled={disabledFields.first_name}
              normalize={trimInitialWhiteSpace}
              tooltipContent={
                shouldReplaceWalkmeTooltips && (
                  <div>
                    The name on the invoice is the legal name under which the client is receiving benefits rather than a
                    nickname. Please note that your client may have registered with Lyra using a different name from the
                    one you were given.
                  </div>
                )
              }
              tooltipIcon={<InfoIcon size={16} />}
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <Field
              name={PAYMENT_FORM.FIELDS.LAST_NAME}
              type='text'
              component={InputField}
              label='Patient Last Name *'
              placeholder='Last Name'
              disabled={disabledFields.last_name}
              normalize={trimInitialWhiteSpace}
            />
          </div>
        </div>

        {showEligibleMemberDetails ? (
          <div className={styles.section}>
            <h2>Eligible Member Details</h2>
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <Field
                  name={PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_FIRST_NAME}
                  type='text'
                  component={InputField}
                  label="Member's First Name *"
                  placeholder='First Name'
                  normalize={trimInitialWhiteSpace}
                />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <Field
                  name={PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_LAST_NAME}
                  type='text'
                  component={InputField}
                  label="Member's Last Name *"
                  placeholder='Last Name'
                  normalize={trimInitialWhiteSpace}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <Field
                  name={PAYMENT_FORM.FIELDS.ELIGIBLE_MEMBER_DOB}
                  type='tel'
                  component={MaskedInputField}
                  mask='11/11/1111'
                  placeholder='MM/DD/YYYY'
                  label="Member's Date of Birth *"
                />
              </div>
            </div>
          </div>
        ) : (
          []
        )}

        <div className={styles.section}>
          <h2>Session Details</h2>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name={PAYMENT_FORM.FIELDS.VISIT_DATE}
                type='tel'
                key={dateInputKey}
                component={DateInputField}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                placeholder='MM/DD/YYYY'
                label='Visit Date *'
                shouldForceTouch
                mask='11/11/1111'
              />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Tooltip
                content='Please choose a sponsoring company'
                show={!selectedCompany || !selectedCompany.length}
                delayHide={0}
              >
                <Field
                  name={PAYMENT_FORM.FIELDS.SESSION_TYPE}
                  label='Session Type *'
                  component={SelectField}
                  disabled={!selectedCompany || !selectedCompany.length || disabledFields.session_type}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (initialEvaluationSessionTypes.includes(e.target.value)) {
                      setModalContent(
                        <PaymentAdditionalInfoContent
                          closeModal={() => setModalContent(null)}
                          title={'Confirm client’s eligibility before submitting payment'}
                          description={
                            'You can confirm your client’s eligibility and benefits using the Eligibility tab.'
                          }
                          url={'/eligibility-checker'}
                        />,
                      )
                    }
                  }}
                  tooltipContent={
                    shouldReplaceWalkmeTooltips && (
                      <div>
                        Select the type of session that took place (individual initial evaluation, individual follow up,
                        couple’s follow up, etc.). Once you’ve selected a session type from the drop down list, your
                        session rate will appear under “session rate” for the session types that you are contracted for.
                        If you are having trouble selecting a session type, make sure that you have clicked on the
                        sponsoring company from the drop down menu under “Patient Details,” rather than typing it in.
                      </div>
                    )
                  }
                  tooltipIcon={<InfoIcon size={16} />}
                  tooltipTextWidth='350px'
                >
                  <option hidden value=''>
                    Select
                  </option>
                  {sessionDropDownOptions}
                </Field>
              </Tooltip>
            </div>
          </div>

          {sessionType === 'other' ? (
            <div className='row'>
              <div className='col-xs-12'>
                <Field
                  name={PAYMENT_FORM.FIELDS.OTHER_SESSION_DESCRIPTION}
                  type='text'
                  component={InputField}
                  label='Session Type Description *'
                  placeholder='Session Description'
                  hintContent='Since you selected "Other", please describe the session'
                />
              </div>
            </div>
          ) : (
            []
          )}

          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name={PAYMENT_FORM.FIELDS.SESSION_DURATION}
                type='text'
                component={InputField}
                label='Session Length (Minutes) *'
                placeholder='Number of minutes'
                tooltipContent={
                  shouldReplaceWalkmeTooltips && (
                    <div>
                      Indicate the length of the session using a numerical value such as “50” for a 50-minute session.
                    </div>
                  )
                }
                tooltipIcon={<InfoIcon size={16} />}
              />
            </div>
            {!differentialPricingRatesEnabled && (
              <div className='col-xs-12 col-sm-6'>
                <Tooltip
                  content='Please choose a sponsoring company'
                  show={!selectedCompany || !selectedCompany.length}
                  delayHide={0}
                >
                  <Field
                    name={PAYMENT_FORM.FIELDS.SESSION_RATE}
                    type=''
                    readonly={sessionType !== 'other'}
                    component={InputField}
                    label='Session Rate *'
                    placeholder='Session Rate'
                    tooltipContent={
                      shouldReplaceWalkmeTooltips && (
                        <div>
                          The session rate will automatically appear once a session type is selected and only for your
                          contracted session types. If you do not see your rate appear after clicking on the appropriate
                          session type, please reference your LOA to ensure you are contracted for that type of
                          treatment. If you confirm that you are contracted for that session type, please reach out to
                          providers@lyrahealth.com as we may need to activate your account.
                        </div>
                      )
                    }
                    tooltipIcon={<InfoIcon size={16} />}
                    tooltipTextWidth='350px'
                  />
                </Tooltip>
              </div>
            )}
          </div>

          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Field
                name={PAYMENT_FORM.FIELDS.MODALITY}
                label='Modality *'
                subLabel={
                  <div>
                    {differentialPricingRatesEnabled ? (
                      <div className={styles['sub-label']}>
                        For compliance and billing accuracy, please select the correct session modality. Intentional
                        misrepresentation of billing information is considered billing fraud, which may impact your
                        contract with Lyra and/or your license.
                      </div>
                    ) : (
                      <div className={styles['sub-label']}>
                        For compliance purposes, please select the appropriate modality for this session.
                      </div>
                    )}
                    <div className={styles['container-text']}>
                      Practice locations we have on file are listed below. For changes, complete an {addressFormLink}.
                    </div>
                  </div>
                }
                component={SelectField}
                disabled={disabledFields.modality}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const modality = e.target.value
                  setCurrentModality(modality)
                  if (modality === 'video' || modality === 'tele_therapy') {
                    locationChangeHandler('')
                  } else {
                    if (addresses?.length === 1) {
                      locationChangeHandler(addresses[0])
                    }
                  }
                }}
              >
                <option hidden value=''>
                  Select
                </option>
                {displayedModalityOptions}
              </Field>
            </div>
            {currentModality === 'in_person' && addresses?.length > 1 && (
              <div
                className='col-xs-12 col-sm-6'
                style={differentialPricingRatesEnabled ? { marginTop: '144px' } : { marginTop: '104px' }}
              >
                <Field
                  name={PAYMENT_FORM.FIELDS.LOCATION}
                  label='Location *'
                  placeholder='In Person Address'
                  labelKey='street1'
                  component={TypeAheadField}
                  options={addresses}
                  multiSelect={false}
                  disabledFields={disabledFields.location}
                  forceMatch
                ></Field>
              </div>
            )}
          </div>
          {differentialPricingRatesEnabled && (
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <Tooltip
                  content='Please choose a sponsoring company'
                  show={!selectedCompany || !selectedCompany.length}
                  delayHide={0}
                >
                  <Field
                    name={PAYMENT_FORM.FIELDS.SESSION_RATE}
                    type=''
                    readonly={sessionType !== 'other'}
                    component={InputField}
                    label='Session Rate *'
                    placeholder='Session Rate'
                    tooltipContent={
                      shouldReplaceWalkmeTooltips && (
                        <div>
                          The session rate will automatically appear once a session type is selected and only for your
                          contracted session types. If you do not see your rate appear after clicking on the appropriate
                          session type, please reference your LOA to ensure you are contracted for that type of
                          treatment. If you confirm that you are contracted for that session type, please reach out to
                          providers@lyrahealth.com as we may need to activate your account.
                        </div>
                      )
                    }
                    tooltipIcon={<InfoIcon size={16} />}
                    tooltipTextWidth='350px'
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-sm-12'>
              <Field
                name={PAYMENT_FORM.FIELDS.ATTENDANCE}
                component={RadioGroup}
                styleType='stroked'
                label='Patient Attendance'
                buttons={[
                  { text: attendanceOptions.attended, value: 'attended' },
                  { text: attendanceOptions.last_minute_cancellation, value: 'last_minute_cancellation' },
                  { text: attendanceOptions.no_show, value: 'no_show' },
                ]}
                disabled={disabledFields.attendance}
              />
            </div>
          </div>
          <div className='row'>
            {attendance?.toLocaleLowerCase() === attendanceOptions.attended.toLocaleLowerCase() &&
              ((!isECDCProvider(loggedInUser) && !hasRole(loggedInUser.roles, ROLES.PAYMENTS_ADMIN)) ||
                !isECDCEnabled) && (
                <div className='col-sm-12'>
                  <Field
                    name={PAYMENT_FORM.FIELDS.DIAGNOSIS}
                    component={TypeAheadField}
                    forceMatch
                    defaultSelected={initialDiagnoses}
                    options={diagnoses}
                    placeholder='Select primary need(s)'
                    label='Primary Need(s) *'
                    disabled={disabledFields.diagnosis}
                    tooltipContent={
                      shouldReplaceWalkmeTooltips && (
                        <div>
                          Use your best judgment for selecting your client’s primary clinical need(s). You can update
                          this information in future invoices. Choose all that apply.
                        </div>
                      )
                    }
                    tooltipIcon={<InfoIcon size={16} />}
                  />
                </div>
              )}
            {showDiagnosisDescription ? (
              <div className='row'>
                <div className='col-xs-12'>
                  <Field
                    name={PAYMENT_FORM.FIELDS.OTHER_DIAGNOSIS}
                    type='text'
                    component={InputField}
                    label='Diagnosis Description *'
                    placeholder='Diagnosis Description'
                    hintContent='Since you selected "Other", please describe the diagnosis'
                    disabled={guaranteedTime}
                  />
                </div>
              </div>
            ) : (
              []
            )}
            {attendance?.toLocaleLowerCase() === attendanceOptions.attended.toLocaleLowerCase() &&
              isECDCEnabled &&
              (isECDCProvider(loggedInUser) || hasRole(loggedInUser.roles, ROLES.PAYMENTS_ADMIN)) && (
                <>
                  <div className='col-sm-12'>
                    <div className={styles['sub-label']}>
                      List primary and secondary clinical targets for this episode with ratings of severity and the
                      evidence-based psychotherapy used in sessions, as permitted by applicable law.
                    </div>
                    <br></br>
                  </div>
                  <div className='col-sm-12'>
                    <Field
                      name={PAYMENT_FORM.FIELDS.PRIMARY_CONDITION}
                      component={TypeAheadField}
                      forceMatch
                      multiSelect={false}
                      options={conditionsList}
                      placeholder='Select primary condition'
                      label='Indicate the primary condition that you are targeting in therapy: *'
                    >
                      <option hidden value=''>
                        Select
                      </option>
                      {conditionsList.map((element: { value: string; label: string }, i) => {
                        return (
                          <option key={i} value={element.value}>
                            {element.label}
                          </option>
                        )
                      })}
                    </Field>
                    {primaryCondition && primaryCondition.length > 0 && primaryCondition[0].value === 'other' ? (
                      <div className='row'>
                        <div className='col-xs-12'>
                          <Field
                            name={PAYMENT_FORM.FIELDS.OTHER_PRIMARY_DESCRIPTION}
                            type='text'
                            component={InputField}
                            label='Condition Type Description *'
                            placeholder='Condition description'
                            hintContent='Since you selected "Other", please describe the condition'
                          />
                        </div>
                      </div>
                    ) : (
                      []
                    )}
                    <Field
                      name={PAYMENT_FORM.FIELDS.PRIMARY_CONDITION_SEVERITY}
                      component={RadioGroup}
                      styleType='stroked'
                      label="For this client's primary condition, indicate the severity:"
                      buttons={[
                        { text: severityOptions.mild, value: 'mild' },
                        { text: severityOptions.moderate, value: 'moderate' },
                        { text: severityOptions.severe, value: 'severe' },
                      ]}
                    />
                    <Field
                      name={PAYMENT_FORM.FIELDS.SECONDARY_CONDITION}
                      component={TypeAheadField}
                      forceMatch
                      options={conditionsList}
                      placeholder='Select all secondary conditions'
                      label='Indicate any secondary, co-occurring conditions (Select all that apply):'
                    />
                    {secondaryCondition &&
                    secondaryCondition.some((item: { label: string; value: string }) => item.value === 'other') ? (
                      <div className='row'>
                        <div className='col-xs-12'>
                          <Field
                            name={PAYMENT_FORM.FIELDS.OTHER_SECONDARY_DESCRIPTION}
                            type='text'
                            component={InputField}
                            label='Secondary Condition Description'
                            placeholder='Secondary Condition description'
                            hintContent='Since you selected "Other", please describe the condition'
                          />
                        </div>
                      </div>
                    ) : (
                      []
                    )}
                    <Field
                      name={PAYMENT_FORM.FIELDS.EBT_TYPE}
                      component={TypeAheadField}
                      forceMatch
                      multiSelect={false}
                      options={ebtTypes}
                      placeholder='Select primary EBT'
                      label='What was the primary Evidence Based Therapy (EBT) that you used for this session?'
                    >
                      <option hidden value=''>
                        Select
                      </option>
                      {ebtTypes.map((element: { value: string; label: string }, i) => {
                        return (
                          <option key={i} value={element.value}>
                            {element.label}
                          </option>
                        )
                      })}
                    </Field>
                    {ebtType && ebtType.length > 0 && ebtType[0].value === 'other' ? (
                      <div className='row'>
                        <div className='col-xs-12'>
                          <Field
                            name={PAYMENT_FORM.FIELDS.OTHER_EBT_DESCRIPTION}
                            type='text'
                            component={InputField}
                            label='EBT Type Description'
                            placeholder='EBT description'
                            hintContent='Since you selected "Other", please describe the EBT used'
                          />
                        </div>
                      </div>
                    ) : (
                      []
                    )}
                  </div>
                </>
              )}
          </div>

          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <h2 className={styles['sub-header']}>
                Discontinue Care
                {shouldReplaceWalkmeTooltips && (
                  <Tooltip
                    delayHide={0}
                    content={
                      <div>
                        <div>
                          Treatment is considered{' '}
                          <BodyText style={{ fontWeight: 'bold' }} text='completed' color={colors.textButton} /> if you
                          and your patient decide that they have achieved their goals and do not need further treatment
                          at this time.
                        </div>
                        <br />
                        <div>
                          Treatment is considered{' '}
                          <BodyText style={{ fontWeight: 'bold' }} text='terminated' color={colors.textButton} /> if
                          treatment has to end for reasons including, but not limited to, patient relocation, change in
                          patient’s employment status, patient stops responding, etc.
                        </div>
                        <br />
                        <div> The patient is welcome to return for care at any time.</div>
                      </div>
                    }
                  >
                    <InfoIcon size={16} />
                  </Tooltip>
                )}
              </h2>
              <Field
                name={PAYMENT_FORM.FIELDS.LAST_SESSION}
                // @ts-expect-error TS(2769): No overload matches this call.
                onChange={lastSessionHandler}
                className={styles['discontinue-checkboxes']}
                component={Checkbox}
                disabled={disabledFields.last_session}
                type='checkbox'
              >
                Last session - treatment completed
              </Field>
              <Field
                name={PAYMENT_FORM.FIELDS.LAST_SESSION_TERMINATED}
                // @ts-expect-error TS(2769): No overload matches this call.
                onChange={lastSessionHandler}
                className={styles['discontinue-checkboxes']}
                component={Checkbox}
                disabled={disabledFields.last_session_terminated}
                type='checkbox'
              >
                Last session - treatment terminated
              </Field>
            </div>
          </div>
        </div>

        <div className={`row ${styles['comments-container']}`}>
          <div className='col-xs-12'>
            <Field
              name={PAYMENT_FORM.FIELDS.NOTES}
              component={InputFieldRFF}
              placeholder='Add a comment'
              label='Clinical Notations'
              multiline={true}
              numberOfLines={3}
              sublabel={
                'For any urgent clinical concerns please reach out [here](https://provider-support.lyrahealth.com/hc/en-us/requests/new)'
              }
              toolTipContent={
                <div className={styles['tooltip-text']}>
                  If there are additional notes you’d like to include, please type them here. If you have a question,
                  please submit a request through the {helpCenterLink}.
                </div>
              }
              toolTipTriggerConfig={{ icon: TOOLTIP_TRIGGER_ICON.INFO }}
              toolTipHoverEnabled={true}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>{referenceNumberField}</div>
        </div>

        {showOutcomesMessage ? (
          <div className={styles['outcomes-message']}>
            <h2 className={styles['outcomes-header']}>Client Outcomes</h2>
            {sessionType ? (
              patientOutcomesAgreed ? (
                <span>
                  {clientOutcomesEligibility ? (
                    <span>
                      <p>
                        We will collect Outcomes data from this client once a month via email. As soon as the client
                        submits responses, we’ll notify you.
                        <br />
                        <a
                          href='https://provider-support.lyrahealth.com/hc/en-us/articles/115012608808-Automated-Outcomes-Collection-Process-and-FAQ'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Learn more
                        </a>
                      </p>
                    </span>
                  ) : (
                    <p>
                      We will not collect Outcomes data from this client. We currently only collect Outcomes data from
                      clients who are 18 years of age or older and who participate in individual therapy, couples
                      therapy, or medication management.
                      <br />
                      <a
                        href='https://provider-support.lyrahealth.com/hc/en-us/articles/360000740168'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Learn more
                      </a>
                    </p>
                  )}
                </span>
              ) : (
                <p>
                  This client has chosen not to submit Outcomes to Lyra.
                  <br />
                  <a
                    href='https://provider-support.lyrahealth.com/hc/en-us/articles/360000740168'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Learn more
                  </a>
                </p>
              )
            ) : (
              <p>Please select a Session Type above.</p>
            )}
          </div>
        ) : (
          []
        )}

        <div className={`row ${styles['submit-container']}`}>
          <div className='col-xs-12'>
            {primaryButtonText === 'Next' && <p>We need some additional information</p>}
            <PrimaryButton
              id='test-requestpayment-submit'
              type='submit'
              disabled={disableSubmitButton}
              isLoading={submitting}
              onKeyDown={onHandleSubmit()}
            >
              {primaryButtonText}
            </PrimaryButton>
          </div>
        </div>
      </div>
      <BaseModal body={modalContent ?? <div />} isOpen={!!modalContent} closeModal={() => setModalContent(null)} />
    </form>
  )
}

type PaymentRequestFormProps = {
  primaryButtonText: string
  providers: ProviderAdminProviderInfo[]
  handleSubmit: Function
  submitting: boolean
  submitFunction: Function
  showEligibleMemberDetails: boolean
  sessionType: string
  dateInputKey: string
  showDiagnosisDescription: boolean
  providerChangeHandler: Function
  showOutcomesMessage: boolean
  patientOutcomesAgreed: boolean
  clientOutcomesEligibility: boolean
  initialCompany: CustomerInfo[]
  initialDiagnoses: { label: string; value: string }[]
  initialModality?: string
  lastSessionHandler: Function
  sponsoringCompanies: CustomerInfo[]
  guaranteedTimeEligible: boolean
  guaranteedTime: boolean
  selectedCompany: CustomerInfo[]
  disableSubmitButton: boolean
  showLyraCode: boolean
  hideDependentField: boolean
  selectedProvider: ProviderAdminProviderInfo
  loggedInUser: ProviderUser
  config: any
  disabledFields: { [key: string]: any }
  lyraCodeValidationUser: UserInfo
  attendance: string
  providerAddresses: Address[]
  locationChangeHandler: Function
  primaryCondition: { label: string; value: string }[]
  secondaryCondition: { label: string; value: string }[]
  ebtType: { label: string; value: string }[]
  rates: ProviderRates
}

PaymentRequestForm.defaultProps = {
  disabledFields: {},
  showLyraCode: false,
  hideDependentField: false,
}

export default PaymentRequestForm
