import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import { ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'
import confirmedImage from '@lyrahealth-inc/ui-core-crossplatform/src/assets/confirmed@2x.png'
import referralsImage from '@lyrahealth-inc/ui-core-crossplatform/src/assets/referrals@2x.png'
import scheduleImage from '@lyrahealth-inc/ui-core-crossplatform/src/assets/schedule@2x.png'

import { actions } from '../../../mixpanel/mixpanelConstants'

export const SchedulingFooterSupportNotConnected = [
  {
    text: 'Connecting Your Calendar With Lyra',
    link: 'https://provider-support.lyrahealth.com/hc/en-us/articles/115008263367-Connecting-Your-Calendar-with-Lyra',
    mixpanelAction: actions.SCHEDULING_FOOTER_CONNECT_CALENDAR,
  },
  {
    text: 'Simple Practice Integration',
    link: 'https://provider-support.lyrahealth.com/hc/en-us/articles/360002018187-Connected-Calendar-FAQ#h_01GYXJ96NR3PGVHA1TY38HG2DB',
    mixpanelAction: actions.SCHEDULING_FOOTER_SIMPLE_PRACTICE,
  },
  {
    text: 'New Appointments Booking Process',
    link: 'https://provider-support.lyrahealth.com/hc/en-us/articles/360002018187-Connected-Calendar-FAQ#h_01GYXK9WV94FSVQJ2600YZGHDN',
    mixpanelAction: actions.SCHEDULING_FOOTER_APPOINTMENT_BOOKING,
  },
  {
    text: 'Will Clients Have Access To Private Calendar',
    link: 'https://provider-support.lyrahealth.com/hc/en-us/articles/360002018187-Connected-Calendar-FAQ#h_01GYXJKVJJ0WD16TFH2J05930B',
    mixpanelAction: actions.SCHEDULING_FOOTER_PRIVATE_CALENDAR,
  },
]

export const SchedulingFooterInternationalNotConnected = [
  {
    text: 'How to Connect Your Calendar to Use Smart Scheduling',
    link: 'https://icas-provider-support.zendesk.com/hc/en-us/articles/33736362526227-How-to-Connect-Your-Calendar-to-Use-Smart-Scheduling',
    mixpanelAction: actions.SCHEDULING_INTERNATIONAL_FOOTER_HOW_TO_CONNECT,
  },
  {
    text: 'How to Set-up and Edit Your Calendar Availability',
    link: 'https://icas-provider-support.zendesk.com/hc/en-us/articles/33280044864275/',
    mixpanelAction: actions.SCHEDULING_INTERNATIONAL_FOOTER_MANAGE_AVAILABILITY,
  },
  {
    text: 'Connected Calendar FAQ',
    link: 'https://icas-provider-support.zendesk.com/hc/en-us/articles/11756823202835-Connected-Calendar-FAQ',
    mixpanelAction: actions.SCHEDULING_INTERNATIONAL_FOOTER_CALENDAR_FAQ,
  },
]

export const SchedulingFooterSupportConnected = [
  {
    text: 'How to Update Your Calendar',
    link: 'https://provider-support.lyrahealth.com/hc/en-us/articles/8846004868755-How-to-Update-Your-Calendar',
    mixpanelAction: actions.SCHEDULING_FOOTER_UPDATE_CALENDAR,
  },
  {
    text: 'Client Did Not Complete In-Take Paperwork',
    link: 'https://provider-support.lyrahealth.com/hc/en-us/articles/360002018187-Connected-Calendar-FAQ#h_01GYXJNPXF3AA1CN5XHA4BKRWB',
    mixpanelAction: actions.SCHEDULING_FOOTER_CLIENT_INTAKE,
  },
  {
    text: 'Calendar Best Practices',
    link: 'https://provider-support.lyrahealth.com/hc/en-us/articles/8547169875219-Calendar-Best-Practices',
    mixpanelAction: actions.SCHEDULING_FOOTER_BEST_PRACTICES,
  },
  {
    text: 'Accepting Your First Referral',
    link: 'https://provider-support.lyrahealth.com/hc/en-us/articles/4406385694099-Accepting-Your-First-Lyra-Referral',
    mixpanelAction: actions.SCHEDULING_FOOTER_ACCEPTING_REFERRAL,
  },
]

export const SchedulingFooterInternationalConnected = [
  {
    text: 'How to Set-up and Edit Your Calendar Availability',
    link: 'https://icas-provider-support.zendesk.com/hc/en-us/articles/33280044864275/',
    mixpanelAction: actions.SCHEDULING_INTERNATIONAL_FOOTER_MANAGE_AVAILABILITY,
  },
  {
    text: 'Connected Calendar FAQ',
    link: 'https://icas-provider-support.zendesk.com/hc/en-us/articles/11756823202835-Connected-Calendar-FAQ',
    mixpanelAction: actions.SCHEDULING_INTERNATIONAL_FOOTER_CALENDAR_FAQ,
  },
  {
    text: 'Salesforce Clinical Portal Help',
    link: 'https://icas-provider-support.zendesk.com/hc/en-us/categories/28668770197779-Salesforce-Clinical-Portal',
    mixpanelAction: actions.SCHEDULING_INTERNATIONAL_FOOTER_PORTAL_HELP,
  },
  {
    text: 'Risk Management Resources',
    link: 'https://icas-provider-support.zendesk.com/hc/en-us/categories/21697974566803-Risk-Management-Resources',
    mixpanelAction: actions.SCHEDULING_INTERNATIONAL_FOOTER_RISK_MANAGEMENT,
  },
]

export const SchedulingLandingInfo = [
  {
    image: referralsImage as ImageSourcePropType,
    title: 'More referrals',
    description: 'Providers are more likely to receive referrals when clients can book directly.',
  },
  {
    image: confirmedImage as ImageSourcePropType,
    title: 'Faster to confirmed appointments',
    description:
      'Lyra members will be able to immediately book a session with you that fits both your schedule and theirs.',
  },
  {
    image: scheduleImage as ImageSourcePropType,
    title: 'Easier Schedule Management',
    description:
      'Your profile is displayed to new clients only when you actually have capacity, saving you from dealing with extra requests.',
  },
]

export const SchedulingCalendarStatuses = {
  noneSelected: {
    badgeText: 'Select Calendar',
    description: 'Select the calendars you wish to connect, then submit for review.',
  },
  selected: {
    badgeText: 'Submit for Review',
    description: 'Check you’ve selected the calendars you wish to connect, then submit for review.',
  },
  'ProviderCalendarStatus.ONLINE_BOOKING_PENDING': {
    badgeText: 'Request Submitted',
    description: 'Your connected calendar will be live for clients to book within 3 business days. Questions? ',
    linkText: 'Contact us',
  },
  'ProviderCalendarStatus.ONLINE_BOOKING_LIVE': {
    badgeText: 'Calendar Live',
    description: 'New clients can schedule an appointment with you at the times shown below.',
  },
}

export const accessRolesForAppointment = ['owner', 'writer']

export const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
}))

export const BodyTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

export const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  marginLeft: 'auto',
}))

export const WalkthroughContentContainer = styled.View({
  maxWidth: '736px',
  alignSelf: 'center',
})

export const ButtonGroupContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  marginLeft: 'auto',
  flexDirection: 'row',
}))

export const LinkButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  justifyContent: 'center',
  marginRight: theme.spacing['32px'],
}))

export const LoadingContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  alignSelf: 'center',
}))

export const DescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

export const LandingHeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
  marginTop: theme.spacing['32px'],
}))

export const SchedulingCTAWrapper = styled.View(({ theme }) => ({
  width: 'auto',
  padding: theme.spacing['24px'],
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: theme.spacing['8px'],
  marginBottom: theme.spacing['40px'],
  boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
}))

export const LandingSubheadContainer = styled.View(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

// This is the error shown if PP-API returns an error on response from "getSchedulingOAuthStatus" action
export const googleOauthInternalError = 'Unable to access Google Calendars, please contact Lyra Support for assistance'

// This is the error shown if the query params are malformed or missing on callback from Google Auth
export const googleOauthAuthError =
  'Google reported an error on authorization. Please contact Lyra Support for assistance'

export type SchedulingWalkthroughComponentProps = {
  connectCalendar: () => void
}
