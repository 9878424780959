/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { isNil } from 'lodash-es'
import styled from 'styled-components/native'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { outcomeUtils, WarningIcon } from '@lyrahealth-inc/ui-core'
import { tID, Tooltip } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './clientsDashboard.module.scss'
import { ClientSessionLimitInfo } from '../../common/components/client/ClientSessionLimitInfo'
import sessionLimitWarning from '../../common/content/sessionLimitWarning'
import sessionResetDate from '../../common/content/sessionResetDate'

const CsvTooltipContainer = styled.View({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexDirection: 'row',
})

const SessionsHeaderTooltip: React.FC = () => {
  const { shouldReplaceWalkmeTooltips } = useFlags()
  return shouldReplaceWalkmeTooltips ? (
    <CsvTooltipContainer>
      Sessions
      <Tooltip
        content='Please note that your client may be seeing or have seen other Lyra providers this year and those sessions are included in the session count.'
        hoverEnabled={true}
        placement='top'
        wrapperStyle={{ marginLeft: 4 }}
      />
    </CsvTooltipContainer>
  ) : (
    <>Sessions</>
  )
}

export const getDashboardTableColumns = (isProgramLevelSessionLimitEnabled: boolean) => {
  return [
    {
      Header: 'Client Name',
      accessor: 'full_name',
      Cell: (row: any) => (
        <div>
          <h3 className='rt-client-name'>{row.value}</h3>
          <a data-test-id='ClientsDashboard-ViewClient' style={{ textDecoration: 'none' }}>
            View profile
          </a>
        </div>
      ),
    },
    {
      Header: <SessionsHeaderTooltip />,
      accessor: 'sessions_remaining',
      sortable: false,
      minWidth: 200,
      Cell: ({
        original: {
          sessions_used,
          sessions_limit,
          open_bhb_session_count,
          open_bhb_session_limit,
          health_plan_status,
          display_visits_per_issue_per_year,
          sessions_limit_by_program,
          sessions_used_by_program,
        },
        original,
        index,
      }: any) => {
        const sessionLimitWarningText = sessionLimitWarning({ client: original, isProgramLevelSessionLimitEnabled })
        const sessionResetDateText = sessionResetDate({ client: original })
        const showBHBCount = open_bhb_session_count > 0 && open_bhb_session_limit > 0
        const sessionsUsed = showBHBCount ? open_bhb_session_count : sessions_used
        const sessionsLimit = showBHBCount ? open_bhb_session_limit : sessions_limit

        return (
          <div>
            <ClientSessionLimitInfo
              program={showBHBCount ? 'hp' : 'eap'}
              displayVisitsPerIssuePerYear={display_visits_per_issue_per_year}
              sessionUsed={sessionsUsed}
              sessionLimit={sessionsLimit}
              usedSessionsByProgram={sessions_used_by_program}
              EAPSessionLimitByProgram={sessions_limit_by_program}
              hideProgram='coaching'
              testID={tID(`ClientDashboard-eap-session-count-${index}`)}
            />
            {health_plan_status && isNil(open_bhb_session_limit) && (
              <div className='rt-assessment-description'>
                May be eligible to use health plan, check profile for details
              </div>
            )}
            {sessionLimitWarningText && (
              <div className='rt-session-description'>
                {sessionLimitWarningText} {sessionResetDateText}
              </div>
            )}
          </div>
        )
      },
    },
    {
      Header: 'Outcomes',
      accessor: 'outcomes',
      maxWidth: 225,
      sortable: false,
      Cell: (row: any) => {
        if (row.value.length === 0) {
          return (
            <div>
              <div className='rt-assessment-name'>Send assessments</div>
              <div className='rt-assessment-description'>No Outcomes possible yet</div>
            </div>
          )
        } else if (row.value[0] && row.value[0].scores) {
          const GADscore = row.value[0].scores.GAD
          const PHQscore = row.value[0].scores.PHQ
          return (
            <div>
              <div className='rt-outcome'>
                <div className='rt-assessment-name'>GAD7 - {GADscore}</div>
                <div className='rt-assessment-description'>
                  {outcomeUtils.convertOutcomeScoreToDescription('GAD', GADscore)}
                </div>
              </div>
              <div className='rt-outcome'>
                <div className='rt-assessment-name'>PHQ9 - {PHQscore}</div>
                <div className='rt-assessment-description'>
                  {outcomeUtils.convertOutcomeScoreToDescription('PHQ', PHQscore)}
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div>
              <div className='rt-assessment-name'>Not submitted</div>
              <div className='rt-assessment-description'>Please remind client</div>
            </div>
          )
        }
      },
    },
    {
      Header: 'Date Submitted',
      id: 'date_submitted',
      maxWidth: 150,
      accessor: 'outcomes',
      // @ts-expect-error TS(7030): Not all code paths return a value.
      sortMethod: (a: any, b: any) => {
        if (a[0] && b[0]) {
          return a[0].response_date > b[0].response_date ? 1 : -1
        }
      },
      // @ts-expect-error TS(7030): Not all code paths return a value.
      Cell: (row: any) => {
        if (row.value.length > 0) {
          return (
            <div className='rt-date'>
              {row.value[0].response_date ? format(parseISO(row.value[0].response_date), 'MM/dd/yyyy') : ''}
            </div>
          )
        }
      },
    },
    {
      Header: '',
      accessor: 'outcomes',
      sortable: false,
      maxWidth: 110,
      // @ts-expect-error TS(7030): Not all code paths return a value.
      Cell: (row: any) => {
        if (row.value[0] && row.value[0].scores && row.value[0].scores['PHQ-SELF-HARM'] > 0) {
          return (
            <div className='rt-alert'>
              <WarningIcon width={12} fillColor={styles.x_alert} /> Self harm
            </div>
          )
        }
      },
    },
  ]
}
