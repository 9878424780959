import React, { FunctionComponent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Pressable } from 'react-native'

import { differenceInMinutes } from 'date-fns'
import { isEmpty, noop } from 'lodash-es'
import { DeepPartial } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import { Assignment, AssignmentResponse, getFriendlyDate, Message } from '@lyrahealth-inc/shared-app-logic'

import { ButtonModifier } from '../../atoms/baseButton/BaseButton'
import { IconButton } from '../../atoms/iconButton/IconButton'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { XIcon } from '../../atoms/icons/XIcon'
import { ExerciseProviderComment } from '../../molecules/exerciseProviderComment/ExerciseProviderComment'
import { FormContentHeader } from '../../molecules/formContentHeader/FormContentHeader'
import { ThemeType, tID } from '../../utils'
import { FormBody } from '../formBody/FormBody'
import { Modal } from '../modal/Modal'

export type ExerciseResponseNavigatorProps = {
  clientName: string
  content: Assignment
  fetchComment: ({ messageId }: { messageId: string }) => Promise<Message>
  onRequestClose: () => void
  initialResponseIndex: number
  submitHandler: (comment: string, response: AssignmentResponse) => Promise<AssignmentResponse>
  supervisorMode: boolean
  track: ({
    event,
    action,
    details,
    page,
    properties,
  }: {
    event: string
    action?: string
    details?: string | { type: string; title: string }
    page?: string
    properties?: Dict
  }) => void
  updateResponse: (data: DeepPartial<AssignmentResponse>) => void
}

const CloseIconContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginBottom: spacing['24px'],
}))

const CommentContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  padding: `0px ${spacing['16px']}`,
}))

const PreviousResponseButtonContainer = styled.View({
  position: 'absolute',
  top: '190px',
  left: '-100px',
})

const NextResponseButtonContainer = styled.View({
  position: 'absolute',
  top: '190px',
  right: '-100px',
})

export const ExerciseResponseNavigator: FunctionComponent<ExerciseResponseNavigatorProps> = ({
  clientName,
  content,
  fetchComment,
  onRequestClose,
  initialResponseIndex,
  submitHandler,
  supervisorMode,
  track,
  updateResponse,
}) => {
  const intl = useIntl()
  const { colors } = useTheme()
  const responses = content.assignment_responses

  const [currentResponse, setCurrentResponse] = useState<AssignmentResponse | undefined>(
    responses && responses[initialResponseIndex],
  )
  const [responseIndex, setResponseIndex] = useState<number>(initialResponseIndex)

  useEffect(() => {
    if (!supervisorMode && !currentResponse?.viewed && updateResponse && !isEmpty(currentResponse)) {
      updateResponse({ ...currentResponse, viewed: true })
    }
  }, [currentResponse, supervisorMode, updateResponse])

  const instructions = content?.instructions
  const { schema, uiSchema } = content?.content_meta_data || {}

  const date = (currentResponse?.submit_date && new Date(currentResponse.submit_date)) || new Date()
  const friendlyDate =
    differenceInMinutes(new Date(), new Date(date ?? 0)) < 1
      ? 'just now'
      : getFriendlyDate({ date, addPreposition: false, displayTime: true })
  const dateDisplay = `Submitted ${friendlyDate}`

  const group = content?.group || content?.content.group
  const title = content?.title || content?.content.title

  const showPreviousResponse = () => {
    track({ event: 'BUTTON_PRESS', action: 'Next/Previous Entry', page: 'Worksheet Entry' })
    if (responses?.length === 0) return
    if (responseIndex === 0) return
    setCurrentResponse(responses && responses[(responseIndex || 0) - 1])
    setResponseIndex(responseIndex - 1)
  }

  const showNextResponse = () => {
    track({ event: 'BUTTON_PRESS', action: 'Next/Previous Entry', page: 'Worksheet Entry' })
    if (responses?.length === 0) return
    if (responses && responseIndex >= responses?.length - 1) return
    setCurrentResponse(responses && responses[(responseIndex || 0) + 1])
    setResponseIndex(responseIndex + 1)
  }

  return (
    <>
      <Modal
        allowScrollBarOnFullscreen={false}
        closeOnScrim
        modalContents={
          <>
            <CloseIconContainer>
              <Pressable onPress={onRequestClose} testID={tID('ExerciseResponseNavigator-closeButton')}>
                <XIcon fillColor={colors.iconDefault} />
              </Pressable>
            </CloseIconContainer>

            <HeaderContainer>
              <FormContentHeader title={title} group={group} dateDisplay={dateDisplay} />
            </HeaderContainer>

            <CommentContainer>
              <ExerciseProviderComment
                clientName={clientName}
                fetchComment={fetchComment}
                response={currentResponse}
                submitHandler={submitHandler}
              />
            </CommentContainer>

            {responseIndex !== 0 && (
              <PreviousResponseButtonContainer>
                <IconButton
                  accessibilityLabel={intl.formatMessage({
                    defaultMessage: 'Previous exercise response',
                    description: 'Label for button that shows the previous response',
                  })}
                  iconColor={colors.iconDarkBackground}
                  leftIcon={<ChevronIcon size={50} direction={ChevronIconDirection.LEFT} />}
                  modifier={ButtonModifier.UNSTYLED}
                  onPress={showPreviousResponse}
                  testID={tID('ExerciseResponseNavigator-previousExercise-btn')}
                />
              </PreviousResponseButtonContainer>
            )}

            {responseIndex !== (responses && responses.length - 1) && (
              <NextResponseButtonContainer>
                <IconButton
                  accessibilityLabel={intl.formatMessage({
                    defaultMessage: 'Next exercise response',
                    description: 'Label for button that shows the next response',
                  })}
                  iconColor={colors.iconDarkBackground}
                  leftIcon={<ChevronIcon size={50} direction={ChevronIconDirection.RIGHT} />}
                  modifier={ButtonModifier.UNSTYLED}
                  onPress={showNextResponse}
                  testID={tID('ExerciseResponseNavigator-nextExercise-btn')}
                />
              </NextResponseButtonContainer>
            )}

            <FormBody
              formButton={undefined}
              hasNewLookAndFeel
              initialValues={currentResponse?.response}
              instructions={instructions}
              intl={intl}
              name={content?.name || content?.content.name}
              readOnly
              saveForm={noop}
              schema={schema}
              uiSchema={uiSchema}
              withPageBreaks={false}
            />
          </>
        }
        onCloseEnd={noop}
        onRequestClose={onRequestClose}
        style={{ marginTop: 30, marginBottom: 30 }}
        visible
      />
    </>
  )
}
