import React, { forwardRef, ReactElement } from 'react'
import { ViewStyle } from 'react-native'

import { ActionSheetOptions, useActionSheet } from '@expo/react-native-action-sheet'
import { FlashList } from '@shopify/flash-list'
import { isPast } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import {
  CalendarCardAppointment,
  getDateFromAppointment,
  getProgramNameFromId,
  MEETING_FORMATS,
  ProgramNames,
  ThemeColors,
} from '@lyrahealth-inc/shared-app-logic'

import { LiveMessagingSessionListIcon, SessionListPhoneIcon, VideoIconWithEllipse } from '../../atoms'
import { LocationIcon } from '../../atoms/icons/LocationIcon'
import { LCTTeensInlineCallout } from '../../molecules'
import { CalendarCard } from '../../molecules/calendarCard/CalendarCard'
import { SpacedView } from '../../templates/content/CommonViews'
import { ThemeType, tID } from '../../utils'

export enum SESSION_LIST_ITEM_TYPE {
  CARD,
  TITLE,
  EMPTY_MESSAGE,
}

export interface SessionListItem {
  type: SESSION_LIST_ITEM_TYPE
  data: JSX.Element | CalendarCardAppointment
  key: string
  canPress?: boolean
  testId?: string
}

export type SessionListProps = {
  title?: ReactElement
  sessions?: CalendarCardAppointment[]
  /** Combined list in order to optimize on mobile (uses Flashlist). Ignores `sessions` if this is set. */
  combinedSessions?: SessionListItem[]
  onSessionPress?: (
    session: CalendarCardAppointment,
    programName: ProgramNames,
    showActionSheetWithOptions: (options?: ActionSheetOptions, callback?: (i?: number) => void) => void,
    navigateToRescheduleSession?: (appointment: CalendarCardAppointment | number) => void,
    onCancelSessionPress?: (appointment: CalendarCardAppointment) => void,
  ) => void
  addToCalendar?: (appointmentId: { appointmentId: number }) => void
  handleReschedulePress?: (appointment: CalendarCardAppointment) => void
  handleCancelPress?: (appointment: CalendarCardAppointment) => void
  onCancelSessionPress?: (appointment: CalendarCardAppointment) => void
  getProviderIcon?: (meetingFormat: string, isInPast: boolean) => ReactElement
  navigateToRescheduleSession?: (appointment: CalendarCardAppointment) => void
  viewSessionRecording?: (sessionID: string) => void
  emptyListMessage?: ReactElement
  testId?: string
  getCardTitle: (appointment: CalendarCardAppointment) => string
  listFooterComponent?: ReactElement
  listHeaderComponent?: ReactElement
  showManageSessionButtons?: boolean
  style?: ViewStyle
  onPressContactCareNavigator: () => void
  currentUserLyraId?: string
}

export const getSessionIcon = (meetingFormat: MEETING_FORMATS, colors: ThemeColors) => {
  switch (meetingFormat) {
    case MEETING_FORMATS.IN_PERSON: {
      return <LocationIcon size={16} isFilled />
    }
    case MEETING_FORMATS.PHONE:
    case MEETING_FORMATS.CALL: {
      return (
        <SessionListPhoneIcon
          backgroundColor={colors.backgroundHighlightPrimary}
          fillColor={colors.iconActive}
          size={24}
        />
      )
    }
    case MEETING_FORMATS.LIVE_MESSAGING: {
      return (
        <LiveMessagingSessionListIcon
          backgroundColor={colors.backgroundHighlightPrimary}
          fillColor={colors.iconActive}
          size={24}
        />
      )
    }
    default: {
      return <VideoIconWithEllipse />
    }
  }
}

const SessionListContainer = styled.View<{ padded: boolean; theme: ThemeType }>(({ theme, padded }) => ({
  height: '100%',
  ...(padded && { marginBottom: theme.breakpoints.isMinWidthDesktop ? theme.spacing['24px'] : 10 }),
}))

const SessionListTitle = styled.View({
  fontSize: '18px',
  fontWeight: 'normal',
  lineHeight: '28px',
  marginBottom: 12,
})

/**
 * Handles rendering a group of calendar cards that represent a session with a title
 * renders no sessions if there are no sessions
 */
let SessionList = forwardRef(
  (
    {
      title,
      sessions,
      combinedSessions,
      onSessionPress,
      addToCalendar,
      handleReschedulePress,
      handleCancelPress,
      getProviderIcon,
      onCancelSessionPress,
      navigateToRescheduleSession,
      viewSessionRecording,
      emptyListMessage,
      testId,
      getCardTitle,
      listFooterComponent,
      listHeaderComponent,
      showManageSessionButtons = true,
      style,
      onPressContactCareNavigator,
      currentUserLyraId,
    }: SessionListProps,
    ref: any,
  ) => {
    const { colors } = useTheme()
    const { showActionSheetWithOptions } = useActionSheet()
    const combinedSessionsList = combinedSessions
      ?.filter((item) => item.type === SESSION_LIST_ITEM_TYPE.CARD)
      .map((item) => item.data) as CalendarCardAppointment[]

    const renderListItem = ({ item }: { item: SessionListItem }) => {
      if (item.type === SESSION_LIST_ITEM_TYPE.TITLE) {
        return (<SessionListTitle testID={tID(`SessionList-heading`)}>{item.data}</SessionListTitle>) as JSX.Element
      } else if (item.type === SESSION_LIST_ITEM_TYPE.EMPTY_MESSAGE) {
        return item.data as JSX.Element
      }

      const session = item.data as CalendarCardAppointment
      const {
        appointmentId,
        subtitle,
        meetingFormat,
        avatarDetails,
        canRescheduleAppointment,
        appointmentStatus,
        changeReason,
        programId,
        providerID,
        sessionNumber,
        pastAppointment,
        userId,
        guardianLyraId,
        childFirstName,
        childLyraId,
      } = session
      const dateTime = getDateFromAppointment(session)
      const programName = getProgramNameFromId(programId) || ProgramNames.DIRECT_ACCESS_THERAPY

      const isParentLedLctTeenBooking =
        guardianLyraId != null && userId !== guardianLyraId && currentUserLyraId === guardianLyraId // the appointment is booked for a teen by their guardian

      const isChildBooking = childFirstName && childLyraId
      const allSessions = combinedSessionsList || sessions
      const onPress =
        onSessionPress && item.canPress && allSessions
          ? () => {
              onSessionPress(
                session,
                programName,
                showActionSheetWithOptions,
                navigateToRescheduleSession
                  ? navigateToRescheduleSession
                  : canRescheduleAppointment
                  ? handleReschedulePress
                  : undefined,
                onCancelSessionPress ? onCancelSessionPress : handleCancelPress,
              )
            }
          : undefined
      const missed =
        appointmentStatus === 'missed' || (appointmentStatus === 'canceled' && changeReason === 'clientInitiated')

      return (
        <>
          <CalendarCard
            key={appointmentId}
            id={appointmentId}
            testId={item.testId}
            title={getCardTitle(session)}
            subtitle={subtitle}
            icon={getSessionIcon(meetingFormat, colors)}
            avatarDetails={avatarDetails}
            onPress={appointmentStatus !== 'canceled' ? onPress : undefined}
            isMissed={missed}
            ref={ref}
            addToCalendarLink={addToCalendar}
            handleReschedulePress={canRescheduleAppointment || getProviderIcon ? handleReschedulePress : undefined}
            handleCancelPress={handleCancelPress}
            providerIcon={
              getProviderIcon &&
              getProviderIcon(appointmentStatus === 'canceled' ? 'cancelled' : meetingFormat, isPast(dateTime))
            }
            viewSessionRecording={viewSessionRecording}
            session={session}
            showManageSessionButton={showManageSessionButtons}
            providerID={providerID}
            disallowSessionUpdate={isParentLedLctTeenBooking}
            childFirstName={isParentLedLctTeenBooking || isChildBooking ? childFirstName : undefined}
          />
          {providerID &&
            !pastAppointment &&
            programName === ProgramNames.TeensTherapy &&
            sessionNumber === 1 &&
            !isParentLedLctTeenBooking && (
              <>
                <SpacedView marginTop='8px' />
                <LCTTeensInlineCallout
                  onPressContactCareNavigator={onPressContactCareNavigator}
                  isBookingStep={false}
                />
                <SpacedView marginBottom='24px' />
              </>
            )}
        </>
      )
    }

    const getKey = (item: SessionListItem) => item.key

    return (
      <SessionListContainer style={style} testID={tID(`SessionList-${testId}`)} padded={!!sessions}>
        {combinedSessions ? (
          <FlashList
            data={combinedSessions}
            renderItem={renderListItem}
            keyExtractor={getKey}
            estimatedItemSize={105}
            contentContainerStyle={{ padding: 16 }}
            ListFooterComponent={listFooterComponent}
            ListHeaderComponent={listHeaderComponent}
          />
        ) : (
          <>
            <SessionListTitle testID={tID(`SessionList-heading`)}>{title}</SessionListTitle>
            {sessions?.length === 0
              ? emptyListMessage
              : sessions?.map((session) => {
                  return renderListItem({
                    item: {
                      data: session,
                      type: SESSION_LIST_ITEM_TYPE.CARD,
                      key: `${session.id}`,
                      canPress: true,
                    },
                  })
                })}
          </>
        )}
      </SessionListContainer>
    )
  },
)
SessionList = React.memo(SessionList)
export { SessionList }
