/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FunctionComponent, useMemo } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { tID } from '../..'
import { ChevronIcon, ChevronIconDirection, Subhead, TextButton } from '../../atoms'
import { InlineBanner } from '../../molecules/inlineBanner/InlineBanner'
import {
  PEMetrics,
  PlatformEngagementPerformanceYearCard,
} from '../../organisms/platformEngagementPerformanceYearCard/PlatformEngagementPerformanceYearCard'
import { SubheadSize } from '../../styles'

export interface PlatformEngagementQuarterlyDetailsProps {
  currentYear: number
  previousYear: number
  currentYearMetrics: PEMetrics | undefined
  previousYearMetrics: PEMetrics | undefined
  onBackPressed: () => void
  onCurrentYearPressed: (isExpanded: boolean) => void
  onPreviousYearPressed: (isExpanded: boolean) => void
}

const Container = styled.View({
  maxWidth: '1275px',
  marginLeft: '84px',
  marginRight: '84px',
  flexGrow: 1,
  gap: '32px',
  paddingVertical: '32px',
})
const CardContainer = styled.View({
  gap: '24px',
})

export const PlatformEngagementQuarterlyDetails: FunctionComponent<PlatformEngagementQuarterlyDetailsProps> = ({
  currentYearMetrics,
  previousYearMetrics,
  currentYear,
  previousYear,
  onBackPressed,
  onCurrentYearPressed,
  onPreviousYearPressed,
}) => {
  const { colors } = useTheme()
  const { platformEngagementQuarterlyDetails } = colors.components
  const { formatMessage } = useIntl()
  const banner = useMemo(() => {
    if (currentYearMetrics == null || !currentYearMetrics?.isEmpty) {
      return null
    }

    return (
      <InlineBanner
        testId={tID('PlatformEngagementEmptyTableBanner')}
        backgroundColor={colors.backgroundHighlightPrimary}
        textColor={platformEngagementQuarterlyDetails.text}
        text={formatMessage({
          defaultMessage: 'Why is this blank? Data will be added after you close your first episode of the quarter',
          description: "Banner that is shown when there's no data in platform engagement dashboard",
        })}
      />
    )
  }, [colors.backgroundHighlightPrimary, currentYearMetrics, formatMessage, platformEngagementQuarterlyDetails.text])
  return (
    <Container>
      <TextButton
        text='Back'
        leftIcon={<ChevronIcon direction={ChevronIconDirection.LEFT} />}
        onPress={onBackPressed}
        testID={tID('PlatformEngagementBackButton')}
      />
      <CardContainer>
        <Subhead size={SubheadSize.LARGE} text='Quarterly details' />
        <PlatformEngagementPerformanceYearCard
          metrics={currentYearMetrics}
          onAccordianPressed={onCurrentYearPressed}
          performanceYear={currentYear}
          initiallyOpen
          banner={banner}
        />
        {previousYearMetrics != null && !previousYearMetrics?.isEmpty && (
          <PlatformEngagementPerformanceYearCard
            metrics={previousYearMetrics}
            performanceYear={previousYear}
            onAccordianPressed={onPreviousYearPressed}
          />
        )}
      </CardContainer>
    </Container>
  )
}
