import React, { useState } from 'react'

import { format } from 'date-fns'
import { isEmpty } from 'lodash-es'

import { Checkbox, DateInputField, SelectField } from '@lyrahealth-inc/ui-core'
import {
  BulletedList,
  colors,
  InputField,
  PrimaryButton,
  SecondaryButton,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './awayMessage.module.scss'
import { OOOStates } from './OutOfOfficeContainer'
import { track } from '../../../../mixpanel/mixpanelTracking'

export const AwayMessage: React.FC<AwayMessageProps> = ({
  closeModal,
  message,
  setMessage,
  scheduledDate,
  setScheduledDate,
  scheduledTime,
  setScheduledTime,
  onContinue,
}) => {
  const [messageValue, setMessageValue] = useState<string | undefined>(message)
  const [sendScheduledChecked, setSendScheduledChecked] = useState<boolean>(!!scheduledDate && !!scheduledTime)
  const [selectedScheduledDate, setSelectedScheduledDate] = useState<string | null>(scheduledDate)
  const [selectedScheduledTime, setSelectedScheduledTime] = useState<string | null>(scheduledTime)

  const timeOptions = []
  for (let hour = 0; hour < 24; hour++) {
    timeOptions.push({
      label: format(new Date().setHours(hour, 0, 0), 'hh:mm aaa'),
      value: format(new Date().setHours(hour, 0, 0), 'HH:mm:ss'),
    })
    timeOptions.push({
      label: format(new Date().setHours(hour, 30, 0), 'hh:mm aaa'),
      value: format(new Date().setHours(hour, 30, 0), 'HH:mm:ss'),
    })
  }

  return (
    <>
      <div className={styles.header}>Tell active clients that you&apos;ll be out of office</div>
      <BulletedList
        data={[
          { value: 'Keep it general: Make sure your message has no PHI' },
          { value: 'Review before you send: OOO messages cannot be edited or retracted' },
        ]}
        textColor={colors.charcoal5}
        bulletColor={colors.secondary_peach4}
      />
      <InputField
        value={messageValue}
        onChange={(e) => {
          setMessageValue(e.nativeEvent.text)
          setMessage(e.nativeEvent.text)
        }}
        name={'AwayMessage-input'}
        multiline
        numberOfLines={7}
        scrollEnabled
      />

      <Checkbox
        key='123'
        input={{
          name: 'ooo-is-scheduled',
          value: sendScheduledChecked,
          onClick: (e: React.ChangeEvent<HTMLInputElement>) => {
            setSendScheduledChecked(e.target.checked)
            if (!e.target.checked) {
              setSelectedScheduledDate(null)
              setSelectedScheduledTime(null)
              setScheduledDate(null)
              setScheduledTime(null)
            } else {
              track({ event: 'SEND_SCHEDULED_OOO_SELECTED' })
            }
          },
          onChange: () => {},
          onBlur: () => {},
          onFocus: () => {},
          checked: sendScheduledChecked,
        }}
        // @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message
        meta={{}}
      >
        <div className={styles['send-later-container']}>Schedule send (optional)</div>
      </Checkbox>
      {sendScheduledChecked && (
        <div className={styles['schedule-container']}>
          <p className={styles.timezone}>Current timezone: {Intl.DateTimeFormat()?.resolvedOptions()?.timeZone}</p>
          <div className={styles['time-select-container']}>
            <DateInputField
              minDate={new Date()}
              input={{
                name: 'ooo-scheduled-date',
                value: selectedScheduledDate,
                onChange: (e: any) => {
                  setSelectedScheduledDate(e)
                  setScheduledDate(e)
                },
                onBlur: () => {},
                onFocus: () => {},
              }}
              // @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message
              meta={{}}
              label='Date*'
              appendFormat={false}
              className={styles['date-selection']}
              placeholder='MM/DD/YYYY'
              // @ts-expect-error TS(2322): Type 'number | null' is not assignable to type 'Da... Remove this comment to see the full error message
              initialValue={!!scheduledDate ? Date.parse(scheduledDate) : null}
            />
            <SelectField
              input={{
                name: 'ooo-scheduled-time',
                value: selectedScheduledTime,
                onChange: (e: any) => {
                  setSelectedScheduledTime(e.target.value)
                  setScheduledTime(e.target.value)
                },
                onBlur: () => {},
                onFocus: () => {},
              }}
              meta={{}}
              label='Time*'
              className={styles['date-selection']}
            >
              <option value='' disabled selected>
                Select a time
              </option>
              {timeOptions.map((time: { value: string; label: string }) => (
                <option key={time.label} value={time.value}>
                  {time.label}
                </option>
              ))}
            </SelectField>
          </div>
        </div>
      )}

      <div className={styles.buttons}>
        <SecondaryButton fullWidth={true} text={'Cancel'} onPress={closeModal} testID={tID('AwayMessage-cancel')} />
        <PrimaryButton
          fullWidth={true}
          text={'Send'}
          onPress={() => onContinue(OOOStates.CONFIRMING_SEND)}
          testID={tID('AwayMessage-send')}
          disabled={
            isEmpty(messageValue) ||
            (sendScheduledChecked && (isEmpty(selectedScheduledDate) || isEmpty(selectedScheduledTime)))
          }
        />
      </div>
    </>
  )
}

type AwayMessageProps = {
  closeModal: () => void
  message?: string | undefined
  setMessage: React.Dispatch<React.SetStateAction<string>>
  scheduledDate: string | null
  setScheduledDate: React.Dispatch<React.SetStateAction<string | null>>
  scheduledTime: string | null
  setScheduledTime: React.Dispatch<React.SetStateAction<string | null>>
  onContinue: (newOperation: string) => void
}

export default AwayMessage
