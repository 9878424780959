import React from 'react'
import { ImageBackground, ImageSourcePropType } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  ContentContainer,
  Headline,
  HeadlineSize,
  Link,
  mailTo,
  PrimaryButton,
  Subhead,
  SubheadSize,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'
import smartSchedulingBackground from '@lyrahealth-inc/ui-core-crossplatform/src/assets/smartSchedulingBackground@2x.png'

import { SchedulingInformation } from './SchedulingInformation'
import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { SCHEDULING_ACTIVATE } from '../../common/constants/routingConstants'
import { getAuthConfig } from '../../data/auth/authSelectors'
import {
  DescriptionContainer,
  LandingHeaderContainer,
  LandingSubheadContainer,
  SchedulingCTAWrapper,
} from '../constants'

const TitleImageBackgroundContainer = styled.View(({ theme }) => ({
  paddingBottom: theme.spacing['24px'],
}))

export const SchedulingLandingBody: React.FC = () => {
  const { colors } = useTheme()
  const navigate = useNavigate()
  const config = useSelector(getAuthConfig)

  const onStartConnecting = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.CONNECT_CALENDAR_START })
    navigate(SCHEDULING_ACTIVATE.route)
  }

  const instructionsLink = () => {
    track({ event: mixpanelEvents.BUTTON_PRESS, action: actions.SCHEDULING_DA_CONNECTION_INSTRUCTIONS })
    window.open(
      'https://provider-support.lyrahealth.com/hc/en-us/articles/115008263367-Enhance-Your-Experience-With-Smart-Scheduling',
      '_blank',
    )
  }

  return (
    <TitleImageBackgroundContainer>
      <ImageBackground source={smartSchedulingBackground as ImageSourcePropType} accessibilityIgnoresInvertColors>
        <ContentContainer>
          <LandingHeaderContainer>
            <Headline level='2' size={HeadlineSize.SMALL} text='Calendar' color={colors.textPrimary} />
          </LandingHeaderContainer>
        </ContentContainer>
        <ContentContainer>
          <SchedulingCTAWrapper>
            <LandingSubheadContainer>
              <Subhead
                level='3'
                color={colors.textPrimary}
                size={SubheadSize.MEDIUM}
                text='Activate Smart Scheduling'
              />
            </LandingSubheadContainer>
            <DescriptionContainer>
              {config?.internationalSmartSchedulingEnabled ? (
                <BodyText
                  color={colors.textSecondary}
                  size={BodyTextSize.SMALL}
                  text={
                    <>
                      Connect your calendar to Lyra so your future clients can book their sessions with you easily and
                      quickly. Get approval from a Lyra representative before you connect a calendar. If you would like
                      to get approval to connect a calendar, please email{' '}
                      <Link
                        text='internationalproviders@lyrahealth.com'
                        onPress={() => {
                          track({
                            event: mixpanelEvents.BUTTON_PRESS,
                            action: actions.SCHEDULING_INTERNATIONAL_APPROVAL_EMAIL,
                          })
                          mailTo('internationalproviders@lyrahealth.com')
                        }}
                        size={BodyTextSize.SMALL}
                      />{' '}
                      for support.
                    </>
                  }
                />
              ) : (
                <BodyText
                  color={colors.textSecondary}
                  size={BodyTextSize.SMALL}
                  text={
                    <>
                      Connect your calendar to Lyra so your future clients can book their sessions with you easily and
                      quickly. First, review{' '}
                      <Link text='these instructions' onPress={instructionsLink} size={BodyTextSize.SMALL} /> in our
                      Help Center. Then, click “Get Started” below.
                    </>
                  }
                />
              )}
            </DescriptionContainer>
            <PrimaryButton
              text='Get started'
              onPress={onStartConnecting}
              testID={tID('SchedulingLandingBody-startWalkthrough')}
            />
          </SchedulingCTAWrapper>
        </ContentContainer>
        <ContentContainer>
          <LandingSubheadContainer>
            <Subhead
              level='3'
              color={colors.textSecondary}
              size={SubheadSize.MEDIUM}
              text='Why use Smart Scheduling?'
            />
          </LandingSubheadContainer>
        </ContentContainer>
        <ContentContainer>
          <SchedulingInformation />
        </ContentContainer>
      </ImageBackground>
    </TitleImageBackgroundContainer>
  )
}
