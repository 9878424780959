import React, { FunctionComponent, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'
import { Popover } from 'react-native-popper'

import styled, { useTheme } from 'styled-components/native'

import { CalendarAlert, CalendarAlertType } from '@lyrahealth-inc/shared-app-logic'

import {
  BodyText,
  ChevronIconDirection,
  ChevronV2Icon,
  GearIcon,
  PressableOpacity,
  QuestionMarkIcon,
  SecondaryButton,
  Subhead,
  TertiaryButton,
  TertiaryIconButton,
  XIcon,
} from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { ProviderCalendarMetricBadge } from '../providerCalendarMetricBadge/ProviderCalendarMetricBadge'

export type ProviderCalendarHeaderProps = {
  onNextPressed: () => void
  onPrevPressed: () => void
  onTodayPressed: () => void
  onSettingsPressed: () => void
  onOpenZendeskPressed: () => void
  rangeText: string
  scheduledSessions: number | null
  clientHours: number | null
  alerts: CalendarAlert[]
  onAlertPressed: (alert: CalendarAlert) => void
}

const Header = styled.View(({ theme }) => ({
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.colors.backgroundPrimary,
  zIndex: 3,
}))

const HeaderContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing['16px'],
  padding: `${theme.spacing['16px']} ${theme.spacing['24px']}`,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const HeaderInnerContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const MetricsContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const Metric = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['12px'],
  padding: `6px ${theme.spacing['8px']}`,
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '8px',
}))

const Border = styled.View(({ theme }) => ({
  height: '1px',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: '1px',
  backgroundColor: theme.colors.borderDefault,
}))

const IconButtonContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const IconButton = styled(TertiaryIconButton)(({ theme }) => ({
  alignSelf: 'center',
  border: `1.5px solid ${theme.colors.borderDefault}`,
  height: '48px',
  width: '48px',
}))

const CenteredIconButton = styled(TertiaryIconButton)({
  alignSelf: 'center',
})

const PopoverContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: '16px',
  padding: theme.spacing['16px'],
  gap: theme.spacing['16px'],
  boxShadow: `0 2px 10px ${theme.colors.shadowLow}`,
}))

const PopoverRow = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['48px'],
}))

const CloseButton = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

const TodayButton = styled(TertiaryButton)(({ theme }) => ({
  alignSelf: 'center',
  borderColor: theme.colors.borderDefault,
  borderWidth: '1.5px',
}))

export const ProviderCalendarHeader: FunctionComponent<ProviderCalendarHeaderProps> = ({
  onNextPressed,
  onPrevPressed,
  onTodayPressed,
  onSettingsPressed,
  onOpenZendeskPressed,
  rangeText,
  scheduledSessions,
  clientHours,
  alerts,
  onAlertPressed,
}) => {
  const theme = useTheme() as ThemeType
  const [isOpen, setIsOpen] = useState(false)
  const pressableRef = useRef(null)
  const bookableAlert = useMemo(() => alerts.find((alert) => alert.type === CalendarAlertType.BOOKABLE), [alerts])
  const scheduledAlert = useMemo(() => alerts.find((alert) => alert.type === CalendarAlertType.SCHEDULED), [alerts])
  return (
    <Header>
      <HeaderContainer>
        <HeaderInnerContainer>
          <TodayButton
            customTextColor={theme.colors.textSecondary}
            text={<FormattedMessage defaultMessage='Today' description='Button text that moves calendar to today' />}
            onPress={onTodayPressed}
          />
          <IconButtonContainer>
            <IconButton leftIcon={<ChevronV2Icon direction={ChevronIconDirection.LEFT} />} onPress={onPrevPressed} />
            <IconButton
              testID={tID('ProviderCalendarHeader-next-button')}
              leftIcon={<ChevronV2Icon direction={ChevronIconDirection.RIGHT} />}
              onPress={onNextPressed}
            />
          </IconButtonContainer>
          <Subhead text={rangeText} size={SubheadSize.LARGE} />
        </HeaderInnerContainer>
        <HeaderInnerContainer>
          <MetricsContainer testID={tID('ProviderCalendarHeader-metrics-client-hours')}>
            <Metric>
              <Subhead size={SubheadSize.LARGE} text={clientHours} />
              <BodyText
                text={
                  <FormattedMessage
                    defaultMessage='Calendar spots'
                    description='Label for calendar spots slots on lc calendar header'
                  />
                }
              />
              {bookableAlert && (
                <ProviderCalendarMetricBadge
                  testID={tID('ProviderCalendarMetricBadge-bookable-badge')}
                  alert={bookableAlert}
                  onPress={() => onAlertPressed(bookableAlert)}
                />
              )}
            </Metric>
            <Metric testID={tID('ProviderCalendarHeader-metrics-scheduled')}>
              <Subhead size={SubheadSize.LARGE} text={scheduledSessions} />
              <BodyText
                text={
                  <FormattedMessage
                    defaultMessage='Scheduled'
                    description='Label for scheduled sessions on lc calendar header'
                  />
                }
              />
              {scheduledAlert && (
                <ProviderCalendarMetricBadge
                  testID={tID('ProviderCalendarMetricBadge-scheduled-badge')}
                  alert={scheduledAlert}
                  onPress={() => onAlertPressed(scheduledAlert)}
                />
              )}
            </Metric>
          </MetricsContainer>
          <CenteredIconButton
            testID={tID('ProviderCalendarHeader-settings-button')}
            leftIcon={<GearIcon color={theme.colors.iconDefault} size={20} isFilled={false} />}
            onPress={onSettingsPressed}
          />
          <View ref={pressableRef}>
            <CenteredIconButton
              testID={tID('ProviderCalendarHeader-help-button')}
              leftIcon={<QuestionMarkIcon fillColor={theme.colors.iconDefault} width={20} />}
              onPress={() => setIsOpen(true)}
            />
          </View>
          <Popover isOpen={isOpen} onOpenChange={setIsOpen} trigger={pressableRef} placement='left top'>
            <Popover.Backdrop />
            <Popover.Content>
              <PopoverContainer>
                <PopoverRow>
                  <Subhead
                    size={SubheadSize.SMALL}
                    text={
                      <FormattedMessage
                        defaultMessage='Need help with your Lyra calendar?'
                        description='Header for provider calendar help popover'
                      />
                    }
                  />
                  <CloseButton hitSlop={60} onPress={() => setIsOpen(false)}>
                    <XIcon size={24} fillColor={theme.colors.iconDefault} />
                  </CloseButton>
                </PopoverRow>
                <SecondaryButton
                  testID={tID('ProviderCalendarHeader-open-zendesk-button')}
                  onPress={onOpenZendeskPressed}
                  text={
                    <FormattedMessage defaultMessage='Open Zendesk' description='Button to get help with calendar' />
                  }
                />
              </PopoverContainer>
            </Popover.Content>
          </Popover>
        </HeaderInnerContainer>
      </HeaderContainer>
      <Border />
    </Header>
  )
}
