import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, PrimaryButton, Subhead, TertiaryButton } from '../../atoms'
import { ProviderBookableCalendarSlotTarget } from '../../molecules'
import { ProviderBookableCalendar, ProviderBookableCalendarRef } from '../../organisms'
import './style.css'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export type ProviderBookableCalendarSetupProps = {
  timeZone?: string
  onSave?: (events: { startTime: string; endTime: string }[]) => void
  onCancel?: () => void
  isSaving?: boolean
  isEditing?: boolean
  initialEvents?: { startTime: string; endTime: string }[]
  bookableTarget: number
  bookableMax: number
  onChange?: (events: { startTime: string; endTime: string }[]) => void
}

const Container = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
  alignItems: 'center',
}))

const Header = styled.View(({ theme }) => ({
  flexDirection: 'row',
  padding: theme.spacing['24px'],
  justifyContent: 'space-between',
  backgroundColor: theme.colors.backgroundPrimary,
  width: '100%',
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
  alignItems: 'center',
}))

const CalendarContainer = styled.View({
  overflow: 'scroll',
  width: '100%',
  height: 'calc(100vh - 114px)',
})

const HeaderText = styled.View(({ theme }) => ({
  gap: theme.spacing['8px'],
}))

const Row = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const RightSection = styled(Row)(({ theme }) => ({
  gap: theme.spacing['32px'],
}))

const ButtonGroup = styled(Row)(({ theme }) => ({
  gap: theme.spacing['16px'],
}))

export const ProviderBookableCalendarSetup: FunctionComponent<ProviderBookableCalendarSetupProps> = ({
  timeZone = 'America/Los_Angeles',
  onSave,
  onCancel,
  isSaving = false,
  isEditing = false,
  initialEvents,
  bookableTarget,
  bookableMax,
  onChange,
}) => {
  const calendarRef = useRef<ProviderBookableCalendarRef>(null)
  const theme = useTheme() as ThemeType
  const [events, setEvents] = useState<{ startTime: string; endTime: string }[]>(initialEvents ?? [])
  useEffect(() => {
    setEvents(initialEvents ?? [])
  }, [initialEvents])

  useEffect(() => {
    onChange?.(events)
  }, [events, onChange])

  return (
    <Container>
      <Header>
        <HeaderText>
          <Subhead
            size={SubheadSize.LARGE}
            text={
              isEditing ? (
                <FormattedMessage
                  defaultMessage='Edit your calendar spots template'
                  description='Header for calendar setup'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Set up your calendar spots template'
                  description='Header for calendar setup'
                />
              )
            }
          />
          {!isEditing && (
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='Clients can see and book these default times. You can adjust availability for specific weeks as needed.'
                  description='Subhead for calendar setup'
                />
              }
              color={theme.colors.textSecondary}
            />
          )}
        </HeaderText>
        <RightSection>
          <ProviderBookableCalendarSlotTarget
            target={bookableTarget}
            max={bookableMax}
            current={events.length}
            showWarningBelowTarget={isEditing}
          />
          <ButtonGroup>
            <TertiaryButton
              testID={tID('ProviderBookableCalendarSetup-cancel-button')}
              text={<FormattedMessage defaultMessage='Cancel' description='Button to cancel calendar setup' />}
              onPress={() => onCancel?.()}
            />
            <PrimaryButton
              loading={isSaving}
              testID={tID('ProviderBookableCalendarSetup-save-button')}
              text={<FormattedMessage defaultMessage='Save' description='Button to save calendar setup' />}
              onPress={() => onSave?.(events)}
            />
          </ButtonGroup>
        </RightSection>
      </Header>
      <CalendarContainer>
        <ProviderBookableCalendar
          ref={calendarRef}
          timeZone={timeZone}
          onEventsChanged={setEvents}
          disableEditing={false}
          initialEvents={initialEvents}
        />
      </CalendarContainer>
    </Container>
  )
}
