import {
  attendanceValues,
  changeNotices,
  changeReasons,
  diagnoses,
  providerUnableToAttendReasons,
  technicalIssuesCancelSelections,
} from '../../../common/constants/appConstants'

export type endOfSessionMetadataValues = {
  attendance: string
  attendanceIneligibility: boolean
  changeReason?: string
  technicalIssuesCancelSelection?: string
  technicalIssuesCancelDetails: string
  providerInitiated?: string
  providerInitiatedDetails?: string
  changeNotice?: string
  changeNoticeDetail?: string
  diagnosis?: string[]
  primary_condition?: string[]
  other_primary_description?: string
  primary_condition_severity?: string
  secondary_condition?: string[]
  other_secondary_description?: string
  underassessment_verification?: string
  guardianConsent?: boolean
  is_last_session?: boolean
  episode_state?: string
  termination_comment?: string
}

export const endOfSessionMetaData = ({
  clientDetails,
  conditionsList,
  diagnosis,
  enhancedLCxDiagnosisEnabled,
  icd10_primary_diagnosis,
  icd10_secondary_diagnosis,
  isLastSessionToClose,
  primary_condition,
  other_primary_description,
  primary_condition_severity,
  secondary_condition,
  other_secondary_description,
  shouldShowGuardianConsent,
  terminationStatuses,
  underassessment_verification,
}: {
  clientDetails: { first_name: string }
  conditionsList: { label: string; value: string }
  diagnosis: [{ label: string; value: string }] | string[]
  enhancedLCxDiagnosisEnabled: boolean
  icd10_primary_diagnosis: string[]
  icd10_secondary_diagnosis: string[]
  isLastSessionToClose: boolean
  primary_condition: [{ label: string; value: string }]
  other_primary_description?: string
  primary_condition_severity?: string
  secondary_condition: [{ label: string; value: string }]
  other_secondary_description?: string
  shouldShowGuardianConsent: boolean
  terminationStatuses: { text: string; value: string; name: string }[]
  underassessment_verification?: boolean
}) => ({
  schema: {
    type: 'object',
    properties: {
      attendance: {
        name: 'attendance',
        type: 'string',
        title: 'Did this session happen?',
        enum: [attendanceValues.ATTENDED, attendanceValues.NO_SHOW, attendanceValues.INELIGIBLE],
        enumNames: ['Yes', 'No', 'Yes, but the client is ineligible to continue care'],
        validation: {
          isRequired: true,
        },
      },
      attendanceIneligibility: {
        name: 'attendanceIneligibility',
        type: 'boolean',
        title:
          'Have you submitted a ticket with Platform Support and received confirmation that the client is ineligible for care?',
        condition: {
          parentField: 'attendance',
          parentValue: attendanceValues.INELIGIBLE,
        },
        validation: {
          hasValue: 1,
        },
      },
      attendanceIneligibilityMessage: {
        content: 'Please confirm with Platform Support whether or not the client is eligible to continue with care.',
        condition: {
          conditionType: 'AND',
          items: [
            {
              parentField: 'attendance',
              parentValue: attendanceValues.INELIGIBLE,
            },
            {
              parentField: 'attendanceIneligibility',
              parentValue: 0,
            },
          ],
        },
      },
      changeReason: {
        name: 'changeReason',
        type: 'string',
        title: "Why didn't this session happen? \n Please consider your selection carefully.",
        enum: changeReasons(clientDetails.first_name).map(({ value }) => value),
        enumNames: changeReasons(clientDetails.first_name).map(({ text }) => text),
        condition: {
          parentField: 'attendance',
          parentValue: attendanceValues.NO_SHOW,
        },
        validation: {
          isRequired: true,
        },
      },
      technicalIssuesCancelSelection: {
        name: 'technicalIssuesCancelSelection',
        type: 'string',
        title:
          'Which of the following best describes the “technical issue” that prevented this session from occurring?',
        enum: technicalIssuesCancelSelections(clientDetails.first_name).map(({ value }) => value),
        enumNames: technicalIssuesCancelSelections(clientDetails.first_name).map(({ text }) => text),
        condition: {
          parentField: 'changeReason',
          parentValue: 'technicalIssues',
        },
        validation: {
          isRequired: true,
        },
      },
      technicalIssuesCancelDetails: {
        name: 'technicalIssuesCancelDetails',
        type: 'string',
        title: 'Additional details on technical issue(s) experienced:',
        condition: {
          parentField: 'changeReason',
          parentValue: 'technicalIssues',
        },
      },
      providerInitiated: {
        name: 'providerInitiated',
        type: 'string',
        title: 'What was the situation?',
        enum: providerUnableToAttendReasons().map(({ value }) => value),
        enumNames: providerUnableToAttendReasons().map(({ text }) => text),
        condition: {
          parentField: 'changeReason',
          parentValue: 'providerInitiated',
        },
        validation: {
          isRequired: true,
        },
      },
      providerInitiatedDetails: {
        name: 'providerInitiatedDetails',
        type: 'string',
        title: 'Additional details on this cancellation',
        condition: {
          parentField: 'providerInitiated',
          parentValue: 'providerUnableToAttendReasonOtherReason',
        },
        validation: {
          isRequired: true,
        },
      },
      changeNotice: {
        name: 'changeNotice',
        type: 'string',
        title: 'What was the situation?',
        enum: changeNotices().map(({ value }) => value),
        enumNames: changeNotices().map(({ text }) => text),
        condition: {
          parentField: 'changeReason',
          parentValue: 'clientCancel',
        },
        validation: {
          isRequired: true,
        },
      },
      ...(!enhancedLCxDiagnosisEnabled && {
        diagnosis: {
          name: 'diagnosis',
          title: 'Diagnosis',
          type: 'string',
          options: diagnoses,
          placeholder: 'Select diagnosis',
          validation: {
            isRequired: true,
          },
          condition: {
            parentField: 'attendance',
            parentValue: attendanceValues.ATTENDED,
          },
        },
      }),
      ...(enhancedLCxDiagnosisEnabled && {
        primary_condition: {
          name: 'primary_condition',
          type: 'string',
          title: 'Indicate the primary diagnosis or condition that you are targeting in therapy:',
          options: conditionsList,
          multiSelect: false,
          saveFieldAsArray: true,
          validation: {
            isRequired: true,
          },
          condition: {
            parentField: 'attendance',
            parentValue: attendanceValues.ATTENDED,
          },
        },
        other_primary_description: {
          name: 'other_primary_description',
          title: 'Since you selected “Other,” please describe the diagnosis or condition',
          type: 'string',
          condition: {
            parentField: 'primary_condition',
            parentValue: ['Other'],
          },
          validation: {
            isRequired: true,
          },
        },
        primary_condition_severity: {
          name: 'primary_condition_severity',
          type: 'string',
          title: 'For this client’s primary condition, indicate the severity:',
          enum: ['mild', 'moderate', 'severe'],
          enumNames: ['Mild', 'Moderate', 'Severe'],
          validation: {
            isRequired: true,
          },
          condition: {
            parentField: 'attendance',
            parentValue: attendanceValues.ATTENDED,
          },
        },
        secondary_condition: {
          name: 'secondary_condition',
          title: 'Indicate any secondary, co-occurring diagnoses and conditions (Select all that apply):',
          type: 'string',
          options: conditionsList,
          placeholder: 'Select diagnosis',
          condition: {
            parentField: 'attendance',
            parentValue: attendanceValues.ATTENDED,
          },
        },
        other_secondary_description: {
          name: 'other_secondary_description',
          title: 'Since you selected “Other,” please describe the diagnosis or condition',
          type: 'string',
          condition: {
            parentField: 'secondary_condition',
            parentValue: ['Other'],
          },
          validation: {
            isRequired: true,
          },
        },
        underassessedInfoPartOne: {
          name: 'underassessedInfoPartOne',
          content:
            'Accurate assessment and diagnosis are key components of high quality care. We appreciate your efforts to improve our diagnoses in Lyra Care Therapy by: \n\n1) Assessing for the diagnoses below, which we found to be likely underassessed and underdiagnosed in our clients: \n\n\t  - Alcohol use\n\t - Substance use\n\t - OCD\n\t - PTSD\n\t - Eating disorder',
          condition: {
            parentField: 'attendance',
            parentValue: attendanceValues.ATTENDED,
          },
        },
        underassessedInfoPartTwo: {
          name: 'underassessedInfoPartTwo',
          content:
            '2) Assessing for anxiety when depression is present and assessing for depression when anxiety is present, as depression and anxiety frequently co-occur. \n\n Please note if these diagnoses are present for the client either in the "primary" or "secondary" fields above. Refer [here](https://bct-lyrahealth.zendesk.com/hc/en-us/articles/31510392635027-Diagnosis-in-Session-Closure-Form-Guidance) for more information on this initiative.',
          condition: {
            parentField: 'attendance',
            parentValue: attendanceValues.ATTENDED,
          },
        },
        underassessment_verification: {
          name: 'underassessment_verification',
          title: `Did you assess ${clientDetails.first_name} for the above diagnoses?`,
          type: 'string',
          enum: [true, false],
          enumNames: ['Yes', 'No, further assessment is needed'],
          validation: {
            isRequired: true,
          },
          condition: {
            parentField: 'attendance',
            parentValue: attendanceValues.ATTENDED,
          },
        },
      }),
      ...(shouldShowGuardianConsent && {
        guardianConsent: {
          name: 'guardianConsent',
          type: 'string',
          title:
            'You must receive written guardian consent prior to closing session 1. Have you received this consent?',
          enum: [true, false],
          enumNames: [
            `Yes, I acknowledge that I have received written consent from ${clientDetails.first_name}’s guardian.`,
            `No, it has been 72 hours since the first session and I have not received written consent, and so will not continue care with ${clientDetails.first_name}. I have informed ${clientDetails.first_name} that I cannot continue with care. I will close the episode.`,
          ],
          condition: {
            parentField: 'attendance',
            parentValue: attendanceValues.ATTENDED,
          },
        },
      }),
      ...(isLastSessionToClose && {
        is_last_session: {
          name: 'is_last_session',
          type: 'string',
          title: 'Do you want to end the episode right now?',
          enum: [true, false],
          enumNames: ['Yes, I want to close the episode', 'No'],
          validation: {
            isRequired: true,
          },
        },
      }),
      episode_state: {
        name: 'episode_state',
        type: 'string',
        multiSelect: false,
        title: 'What is the reason for ending the episode?',
        enum: terminationStatuses.map(({ value }: any) => value),
        enumNames: terminationStatuses.map(({ text }: any) => text),
        condition: {
          parentField: 'is_last_session',
          parentValue: true,
        },
        validation: {
          isRequired: true,
        },
      },
      termination_comment: {
        name: 'termination_comment',
        type: 'string',
        title: 'Comment',
        condition: {
          parentField: 'is_last_session',
          parentValue: true,
        },
      },
    },
  },
  uiSchema: {
    'ui:order': [
      'attendance',
      'attendanceIneligibility',
      'attendanceIneligibilityMessage',
      'changeReason',
      'technicalIssuesCancelSelection',
      'technicalIssuesCancelDetails',
      'providerInitiated',
      'providerInitiatedDetails',
      'changeNotice',
      'changeNoticeDetail',
      'diagnosis',
      'primary_condition',
      'other_primary_description',
      'primary_condition_severity',
      'secondary_condition',
      'other_secondary_description',
      'underassessedInfoPartOne',
      'underassessedInfoPartTwo',
      'underassessment_verification',
      'guardianConsent',
      'is_last_session',
      'episode_state',
      'termination_comment',
    ],
    attendance: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    attendanceIneligibility: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    changeReason: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    technicalIssuesCancelSelection: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    technicalIssuesCancelDetails: {
      'ui:widget': 'textarea',
    },
    providerInitiated: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    providerInitiatedDetails: {
      'ui:widget': 'textarea',
    },
    changeNotice: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    changeNoticeDetail: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    diagnosis: {
      'ui:widget': 'typeAhead',
    },
    primary_condition: {
      'ui:widget': 'typeAhead',
    },
    other_primary_description: {
      'ui:widget': 'text',
    },
    primary_condition_severity: {
      'ui:widget': 'radio',
    },
    secondary_condition: {
      'ui:widget': 'typeAhead',
    },
    other_secondary_description: {
      'ui:widget': 'text',
    },
    underassessment_verification: {
      'ui:widget': 'radio',
    },
    guardianConsent: {
      'ui:widget': 'radio',
    },
    is_last_session: {
      'ui:widget': 'radio',
      'ui:options': { styleType: '' },
    },
    episode_state: {
      'ui:widget': 'select',
    },
    termination_comment: {
      'ui:widget': 'textarea',
    },
  },
  initialValues: {
    is_last_session: false,
    ...(diagnosis && { diagnosis }),
    ...(primary_condition && { primary_condition }),
    ...(other_primary_description && { other_primary_description }),
    ...(secondary_condition && { secondary_condition }),
    ...(other_secondary_description && { other_secondary_description }),
    ...(primary_condition_severity && { primary_condition_severity }),
    ...(underassessment_verification && { underassessment_verification }),
    ...(icd10_primary_diagnosis && { icd10_primary_diagnosis }),
    ...(icd10_secondary_diagnosis && { icd10_secondary_diagnosis }),
  },
})
