import React from 'react'

import { QuestionResponse } from '@lyrahealth-inc/shared-app-logic'

import styles from './outcomesResponseDetails.module.scss'
import { outcomeChartConstants } from '../../constants/outcomeConstants'
import outcomeUtils from '../../utils/outcomeUtils'

const OutcomeResponseDetails: React.FC<OutcomeResponseDetailsProps> = ({ viewData }) => {
  const assessment = outcomeChartConstants.find((obj) => obj.name === viewData?.assessmentType)
  const ranges = assessment?.ranges

  const getAnswerDescription = () => {
    switch (viewData?.assessmentType) {
      case 'PSS':
      case 'WELL-BEING':
        return (
          <p>
            Over the <b>last week</b>, how often have you experienced the following?
          </p>
        )
      case 'TFM':
        return <p>This past week, how many drinks did you consume on each day?</p>
      case 'BDD':
      case 'PARENTAL-STRESS':
      case 'PARENTING-TASK':
      case 'CGSQ':
      case 'LEAPS':
        return <></>
      case 'RCADS-ANXIETY':
      case 'RCADS-DEPRESSION':
      case 'RCADS-TOTAL':
        return (
          <p>
            Please select the option that shows how often each of these things happen to you. There are no right or
            wrong answers.
          </p>
        )
      default:
        return (
          <p>
            Over the <b>last week</b>, how often have you been bothered by any of the following problems?
          </p>
        )
    }
  }

  return (
    <div>
      {viewData?.clientName ? (
        <h3 className={styles['client-name']} data-test-id='OutcomeResponseDetails-clientName'>
          {viewData?.clientName}
        </h3>
      ) : null}
      <h2 className={styles['main-header']} data-test-id='OutcomeResponseDetails-mainHeader'>
        {viewData?.title}
      </h2>
      <div className={styles['overview-container']}>
        <div>
          <h4 data-test-id='OutcomeResponseDetails-dateSubmitted-header'>Date Submitted</h4>
          <p data-test-id='OutcomeResponseDetails-dateSubmitted-value'>{viewData?.dateSubmitted}</p>
        </div>
        <div>
          <h4 data-test-id='OutcomeResponseDetails-finalScore-header'>Final Score</h4>
          <p data-test-id='OutcomeResponseDetails-finalScore-value'>
            {viewData?.score} {outcomeUtils.convertOutcomeScoreToDescription(viewData?.assessmentType, viewData?.score)}
          </p>
        </div>
      </div>
      <div data-test-id='OutcomeResponseDetails-description'>{getAnswerDescription()}</div>
      <div className={styles['list-container']}>
        {viewData?.answers?.map((value, index) => {
          return (
            <div className={styles['list-item']} key={value['question-number']}>
              <p className={styles.counter}>{index + 1} -</p>
              <p className={styles.question}>{value.question}</p>
              <p className={styles.answer}>
                {value['answer-text'] ||
                  (value.groups.some((group) => group.includes('TFM') || group.includes('PARENTING-TASK')) &&
                    value.score)}
              </p>
            </div>
          )
        })}
      </div>
      {(assessment?.individualAnswerDescriptions || assessment?.finalScoreDescriptions) && (
        <div className={styles['score-key']}>
          {assessment?.individualAnswerDescriptions && (
            <div>
              <h4>Individual Answers Range</h4>
              {assessment?.answers?.map((answer) => (
                <p key={answer?.value}>
                  <span>{answer?.value}</span>
                  {answer?.label}
                </p>
              ))}
            </div>
          )}
          {assessment?.finalScoreDescriptions && (
            <div className={styles['range-container']}>
              <h4>Final Score Range</h4>
              {ranges?.map((range) => (
                <p key={range?.lower}>
                  <span>
                    {range?.lower}
                    {range?.upper ? '-' + range?.upper : '+'}
                  </span>
                  {range?.description}
                </p>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

type OutcomeResponseDetailsProps = {
  viewData: {
    clientName: string
    title: string
    dateSubmitted: string
    assessmentType: string
    score: string | number | null | undefined
    answers: QuestionResponse[] | undefined
  }
}

export default OutcomeResponseDetails
