import { get, isNil } from 'lodash-es'

import { outcomeChartConstants } from '../constants/outcomeConstants'

const convertOutcomeScoreToDescription = (type: $TSFixMe, score: $TSFixMe) => {
  if (isNil(score)) {
    return 'No score'
  }
  const assessment = outcomeChartConstants.find((obj) => obj.name === type)
  const ranges = get(assessment, 'ranges')
  if (type === 'PHQ') {
    switch (true) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score <= ranges[0].upper:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[0].description
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score >= ranges[1].lower && score <= ranges[1].upper:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[1].description
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score >= ranges[2].lower && score <= ranges[2].upper:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[2].description
      default:
        return 'Out of range'
    }
  }
  if (type === 'PHQ-LIFE') {
    switch (true) {
      case score === 0:
        return 'Not difficult at all'
      case score === 1:
        return 'Somewhat difficult'
      case score === 2:
        return 'Very difficult'
      case score === 3:
        return 'Extremely difficult'
      default:
        return 'Out of range'
    }
  }
  if (type === 'PHQ-SELF-HARM') {
    switch (true) {
      case score === 0:
        return 'Not at all'
      case score === 1:
        return 'Several days'
      case score === 2:
        return 'More than half the days'
      case score === 3:
        return 'Nearly everyday'
      default:
        return 'Out of range'
    }
  }
  if (type === 'GAD') {
    switch (true) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score <= ranges[0].upper:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[0].description
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score >= ranges[1].lower && score <= ranges[1].upper:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[1].description
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score >= ranges[2].lower:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[2].description
      default:
        return 'Out of range'
    }
  }
  if (type === 'SATISFACTION') {
    switch (true) {
      case score === 0:
        return 'Dissatisfied'
      case score === 1:
        return 'Somewhat Satisfied'
      case score === 2:
        return 'Satisfied'
      case score === 3:
        return 'Very Satisfied'
      default:
        return 'Out of range'
    }
  }
  if (type === 'WSAS') {
    switch (true) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score <= ranges[0].upper:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[0].description
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score >= ranges[1].lower && score <= ranges[1].upper:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[1].description
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      case score >= ranges[2].lower:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return ranges[2].description
      default:
        return 'Out of range'
    }
  }

  if (type === 'LEAPS') {
    const matchedRange = ranges?.find(
      (range) =>
        (!range.lower && score <= range.upper) || (range.lower && score >= range.lower && score <= range.upper),
    )

    if (!matchedRange || !matchedRange.description) {
      return 'Out of range'
    }

    return matchedRange.description
  }

  return ''
}

export default {
  convertOutcomeScoreToDescription,
}
